import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { Fragment, useState } from "react";
import { cartStore } from "../../../app/stores/Cart";
import { ContactDetails, DeliveryDetails, ShippingDetails } from "../../../models/DB";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import OrdersService from "../../../services/supabase/OrdersService";
import { BasicButton } from "../../../components/BasicButton";
import { ContactForm } from "./ContactForm";
import { Review } from "./Review";
import { ShippingForm } from "./ShippingForm";
import { useScreenComponent } from "../../../app/hooks/custom/useScreenComponent";
import { userStore } from "../../../app/stores/User";
import { navigationStore } from "../../../app/stores/Navigation";
import { DefaultRoute } from "../../../models/enums/DefaultRoute";
import { primaryColor } from "../../../config/constants";

export const Checkout = ({ cartItems, onComplete, onReset }) => {
	const [activeStep, setActiveStep] = useState(1);
	const [invoiceId, setInvoiceId] = useState<string>("");
	const [contactDetails, setContactDetails] = useState<ContactDetails>(
		{
			firstName: "",
			lastName: "",
			email: "",
			contactNo: "",
		}
	);
	const [shippingDetails, setShippingDetails] = useState<ShippingDetails & DeliveryDetails>(
		{
			addressLine1: "",
			addressLine2: "",
			country: "",
			city: "",
			region: "",
			zip: "",
			deliveryType: "",
			deliveryFee: 0,
			suburb: "",
		}
	);
	const clearCart = cartStore((state) => state.clearCart);
	const { updateShowNavigationBar, updateDefaultRoute } = navigationStore((state) => state);
	const { user: { info: { email } } } = userStore((state) => state);
	const steps = ["Shipping Address", "Contact Details", "Review your order"];
	const { createOrder } = OrdersService();
	const { navigateToScreen } = useScreenComponent();

	const confirmOrder = async () => {
		try {
			if (!email) {
				console.log("Email is required");
				updateShowNavigationBar(true);
				updateDefaultRoute(DefaultRoute.Login);
				navigateToScreen(ScreenComponent.Login);
				return;
			}

			const invoiceId = await createOrder(
				cartItems,
				contactDetails,
				shippingDetails,
				email
			);

			setInvoiceId(invoiceId);
			setActiveStep(activeStep + 1);
		} catch (error) {
			console.error("Error confirming order:", error);
		}
	};

	const ThankYou = () => {
		return (
			<Fragment>
				<Typography
					variant={"h5"}
					gutterBottom
					textAlign={"center"}
				>
					Thank you for your order.
				</Typography>
				<Typography
					variant={"subtitle1"}
					fontWeight={600}
					textAlign={"center"}
				>
					{`Your order number is #${invoiceId}`}
				</Typography>
				<Typography
					variant={"subtitle2"}
					textAlign={"center"}
				>
					{`We have emailed your order confirmation, and will send you an update when your order has shipped.`}
				</Typography>
				<Grid
					container
					justifyContent={"center"}
					mt={4}
					padding={2}
				>
					<BasicButton
						sx={{ flex: 0.5, height: 45, minHeight: 45 }}
						title={"Continue Shopping"}
						onClick={() => {
							clearCart();
							navigateToScreen(ScreenComponent.Shop);
							if (onComplete) onComplete();
						}}
					/>
				</Grid>
			</Fragment>
		);
	};

	const Form = () => {
		switch (activeStep) {
			case 1:
				return (
					<ShippingForm
						onBack={() => {
							onReset();
						}}
						onNext={(details) => {
							console.log("shipping details", JSON.stringify(details, null, 2));
							setShippingDetails(details as ShippingDetails & DeliveryDetails);
							setActiveStep(activeStep + 1);
						}}
					/>
				);
			case 2:
				return (
					<ContactForm
						onBack={() => setActiveStep(activeStep - 1)}
						onNext={(details) => {
							setContactDetails(details);
							setActiveStep(activeStep + 1);
						}}
					/>
				);
			case 3:
				return (
					<Review
						onBack={() => setActiveStep(activeStep - 1)}
						shippingDetails={shippingDetails}
						contactDetails={contactDetails}
						onConfirm={confirmOrder}
					/>
				);
			case 4:
				return <ThankYou />;
			default:
				return null;
		}
	};

	return (
		<Fragment>
			<Container
				component={"main"}
				maxWidth={"md"}
				sx={{ mt: 10, mb: 4 }}
			>
				<Paper
					variant={"outlined"}
					sx={{
						my: { xs: 3, md: 6 },
						p: { xs: 4, md: 6 },
						boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.1)",
					}}
				>
					<Typography
						component={"h1"}
						variant={"h4"}
						align={"center"}
					>
						Checkout
					</Typography>
					<Stepper
						activeStep={activeStep - 1}
						sx={{
							py: 6,
							// completed icon color
							"& .MuiStepIcon-root.Mui-completed": {
								color: primaryColor,
							},
							// active icon color
							"& .MuiStepIcon-root.Mui-active": {
								color: primaryColor,
							},
						}}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>

					{/* <Form /> */}
					<Form />
				</Paper>
			</Container>
		</Fragment>
	);
};
