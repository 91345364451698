import { DoneRounded, Upload } from "@mui/icons-material"
import { TableRow, TableCell, Collapse, Box, Grid, Typography, Button, FormControlLabel, Switch } from "@mui/material"
import { BasicButton } from "../../../../../components/BasicButton"
import { Image } from "react-native"
import { Product } from "../../../../../models/DB"
import { useEffect, useState } from "react"
import { productStore } from "../../../../../app/stores/Product"
import { primaryColor, stateErrorColor, stateSuccessColor } from "../../../../../config/constants"
import PortalService from "../../../../../services/supabase/PortalService"
import { BasicTextField } from "../../../../../components/BasicTextField"

interface ProductEditorProps {
    product: Product;
    open: boolean;
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    setIsDeleted: (isDeleted: boolean) => void;
}

export const ProductEditor = ({
    product,
    open,
    isEditing,
    setIsEditing,
    setIsDeleted,
}: ProductEditorProps) => {

    const portalService = PortalService();

    const { allProducts, updateProducts } = productStore();
    const [isLoading, setIsLoading] = useState(false);
    const [imagePreviews, setImagePreviews] = useState<{ [key: string]: string | null }>(
        Object.keys(product.images).reduce((acc, key) => ({ ...acc, [key]: product.images[key].url }), {})
    );
    const [productData, setProductData] = useState<Product>({
        ...product,
        variantType: product.variantType || "",
        discount: product.discount || 0,
        inStock: product.inStock || false,
        images: product.images || {},
        selectedVariant: product.selectedVariant || "",
        variantOptions: product.variantOptions || [],
        created: product.created || "",
        updated: product.updated || "",
    });
    const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File | null }>({});

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        setIsLoading(true);
        const validImageFiles = Object.values(selectedFiles).filter((file): file is File => file !== null);
        const updatedProduct = await portalService.updateProduct(productData, validImageFiles);

        if (updatedProduct) {
            const updatedProducts = allProducts.map((product: Product) => {
                if (product.id !== updatedProduct.id) {
                    return product;
                }
                return updatedProduct;
            });

            updateProducts(updatedProducts);
        } else {
            console.log("Error updating product");
        }

        setIsLoading(false);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setProductData({
            ...product,
            variantType: product.variantType || "",
            discount: product.discount || 0,
            inStock: product.inStock || false,
            images: product.images || {},
            selectedVariant: product.selectedVariant || "",
            variantOptions: product.variantOptions || [],
            created: product.created || "",
            updated: product.updated || "",
        });
        setImagePreviews(Object.keys(product.images).reduce((acc, key) => ({ ...acc, [key]: product.images[key].url }), {}));
        setIsEditing(false);
    };

    const handleDelete = async () => {
        setIsLoading(true);
        const deletedProduct = await portalService.deleteProduct(productData.id, productData.code);
        if (deletedProduct) {
            const updatedProducts = allProducts.filter((product: Product) => product.id !== deletedProduct.id);
            updateProducts(updatedProducts);
            setIsDeleted(true);
        }
        setIsLoading(false);
    };

    const handleImageUpload = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const renamedFile = new File([file], `${type}`, { type: file.type });

                setProductData({
                    ...productData,
                    images: {
                        ...productData.images,
                        [type]: { url: reader.result as string }
                    }
                });
                setImagePreviews({ ...imagePreviews, [type]: reader.result as string });
                setSelectedFiles({ ...selectedFiles, [type]: renamedFile });
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#f7f7f7" }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ marginX: 2, marginY: 4, padding: 1 }}>
                        <Grid container spacing={2} justifyContent={"space-between"}>
                            <Grid item xs={6} sm={6}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                    sx={{
                                        fontFamily: "primaryFont",
                                        letterSpacing: 0.5,
                                        fontWeight: "bold"
                                    }}
                                >
                                    Details
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} container justifyContent={{ xs: "flex-end", sm: "flex-end" }}>
                                {isEditing ? (
                                    <Grid>
                                        <BasicButton
                                            title="Save"
                                            variant="contained"
                                            onClick={handleSave}
                                            startIcon={<DoneRounded />}
                                            sx={{ marginRight: 1, maxHeight: 30, fontSize: 9 }}
                                            style={{ backgroundColor: stateSuccessColor }}
                                            isLoading={isLoading}
                                        />
                                        <BasicButton
                                            title="Cancel"
                                            variant="outlined"
                                            onClick={handleCancel}
                                            sx={{ maxHeight: 30, fontSize: 9, backgroundColor: "white" }}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid>
                                        <BasicButton
                                            title="Edit"
                                            variant="outlined"
                                            onClick={handleEdit}
                                            sx={{ marginRight: 1, maxHeight: 30, fontSize: 9, backgroundColor: "white" }}
                                        />
                                        <BasicButton
                                            title="Delete"
                                            variant="contained"
                                            onClick={handleDelete}
                                            sx={{ maxHeight: 30, fontSize: 9 }}
                                            style={{ backgroundColor: stateErrorColor }}
                                            isLoading={isLoading}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2.5} mt={{ xs: 6, sm: 0 }}>
                            <Grid item xs={12} md={4} mb={4}>
                                <Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                fontFamily: "primaryFont",
                                                letterSpacing: 0.5,
                                                textAlign: { xs: "center", md: "left" }
                                            }}
                                        >
                                            Product Images
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body2"
                                            mt={1}
                                            sx={{
                                                fontFamily: "primaryFont",
                                                letterSpacing: 0.5,
                                                textAlign: { xs: "center", md: "left" }
                                            }}
                                        >
                                            {`Select an image to add or replace`}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="body2"
                                            mb={3}
                                            sx={{
                                                fontFamily: "primaryFont",
                                                letterSpacing: 0.5,
                                                textAlign: { xs: "center", md: "left" }
                                            }}
                                        >
                                            {`You can upload up to 3 images`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} justifyContent={"center"}>
                                    {/* Product Images */}
                                    {["thumbnail", "image1", "image2"].map((type) => (
                                        <Grid item xs={4} key={type}>
                                            <Button
                                                fullWidth
                                                variant="text"
                                                component="label"
                                                sx={{
                                                    aspectRatio: "1 / 1",
                                                    padding: 0,
                                                    borderRadius: 6,
                                                    overflow: "hidden",
                                                    borderStyle: "solid",
                                                    borderWidth: 1.75,
                                                    borderColor: primaryColor,
                                                }}
                                            >
                                                <Image
                                                    source={
                                                        imagePreviews[type] ?
                                                            { uri: imagePreviews[type] } :
                                                            require("../../../../../assets/no-image.png")
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        aspectRatio: "1 / 1",
                                                        resizeMode: "cover",
                                                    }}
                                                />
                                                <input type="file" hidden disabled={!isEditing} onChange={handleImageUpload(type)} />
                                            </Button>
                                            <Typography
                                                variant="body2"
                                                mt={1.5}
                                                sx={{
                                                    fontFamily: "primaryFont",
                                                    letterSpacing: 0.5,
                                                    textAlign: "center"
                                                }}
                                            >
                                                {type.charAt(0).toUpperCase() + type.slice(1).replace(/([a-zA-Z])(\d)/g, "$1 $2")}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            {/* Product Details */}
                            <Grid item xs={12} md={4}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <BasicTextField
                                            disabled
                                            sx={{ backgroundColor: "white" }}
                                            size="small"
                                            label="SKU"
                                            value={productData.code}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicTextField
                                            sx={{ backgroundColor: "white" }}
                                            size="small"
                                            label="Name"
                                            value={productData.name}
                                            onChange={(e) => setProductData({ ...productData, name: e.target.value })}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicTextField
                                            sx={{ backgroundColor: "white" }}
                                            size="small"
                                            label="Price"
                                            value={productData.price}
                                            onChange={(e) => setProductData({ ...productData, price: parseFloat(e.target.value) })}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicTextField
                                            sx={{ backgroundColor: "white" }}
                                            size="small"
                                            label="Stock On Hand"
                                            value={productData.stockOnHand}
                                            onChange={(e) => setProductData({ ...productData, stockOnHand: parseInt(e.target.value) })}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicTextField
                                            sx={{ backgroundColor: "white" }}
                                            size="small"
                                            label="Category"
                                            value={productData.category}
                                            onChange={(e) => setProductData({ ...productData, category: e.target.value })}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicTextField
                                            sx={{ backgroundColor: "white" }}
                                            size="small"
                                            label="Discount"
                                            value={productData.discount}
                                            onChange={(e) => setProductData({ ...productData, discount: parseFloat(e.target.value) })}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <BasicTextField
                                            sx={{ backgroundColor: "white" }}
                                            size="small"
                                            label="Variant Type"
                                            value={productData.variantType}
                                            onChange={(e) => setProductData({ ...productData, variantType: e.target.value })}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <BasicTextField
                                            sx={{ backgroundColor: "white" }}
                                            size="small"
                                            label="Variant Options"
                                            value={productData.variantOptions.join(", ")}
                                            onChange={(e) => setProductData({ ...productData, variantOptions: e.target.value.split(", ") })}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item alignSelf="flex-end" mr={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={productData.inStock}
                                                    onChange={(e) => setProductData({ ...productData, inStock: e.target.checked })}
                                                    disabled={!isEditing}
                                                    color={"secondary"}
                                                />
                                            }
                                            label="In Stock"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}
