import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Image, StyleSheet, TouchableOpacity } from "react-native";
import { primaryColor } from "../config/constants";

interface ImagePreviewerProps {
    images: { [key: string]: { url: string } };
}

export const ImagePreviewer: React.FC<ImagePreviewerProps> = ({ images }) => {
    const imageArray = Object.values(images);
    const defaultImage = require("../assets/no-image.png");
    const [selectedImage, setSelectedImage] = useState(imageArray[0]?.url || defaultImage);

    return (
        <Grid container spacing={1} justifyContent={"center"} mt={{ xs: 0, md: 2 }}>
            <Image source={{ uri: selectedImage ? selectedImage : defaultImage }} style={styles.mainImage} />
            <Grid container spacing={1} justifyContent={"center"} mt={2}>
                {imageArray.length > 0 && imageArray.map((image, index) => (
                    <TouchableOpacity
                        key={index}
                        onPress={() => setSelectedImage(image.url)}
                    >
                        <Image
                            source={{ uri: image.url ? image.url : defaultImage }}
                            style={[
                                styles.thumbnail,
                                { borderColor: primaryColor }
                            ]}
                        />
                    </TouchableOpacity>
                ))}
                {
                    imageArray.length === 0 &&
                    <Grid item>
                        <Image source={defaultImage} style={styles.thumbnail} />
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

const styles = StyleSheet.create({
    mainImage: {
        width: "80%",
        aspectRatio: 1 / 1,
        resizeMode: "contain",
        borderRadius: 32,
        alignSelf: "center",
        borderWidth: 2,
        borderColor: "#ececec",
    },
    thumbnail: {
        width: 60,
        height: 60,
        borderRadius: 12,
        margin: 5,
        borderWidth: 1,
        borderColor: "#ececec",
        resizeMode: "contain",
    },
});
