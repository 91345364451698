import { useRoute } from "@react-navigation/native";
import React, { useEffect } from "react";
import { Image, StyleSheet, Text, TouchableHighlight, View } from "react-native";
import { appConfigStore } from "../../app/stores/AppConfig";
import { navigationStore } from "../../app/stores/Navigation";
import { AppLogo } from "../../config/constants";

export const HowItWorks = () => {
	const darkMode = appConfigStore((state) => state.darkMode);
	const heroImage = require(`../../assets/${AppLogo}`)
	const updateSplashLoaded = navigationStore((state) => state.updateSplashLoaded);

	const route = useRoute();
	useEffect(() => {
		console.log("route info: ", route.params);
		updateSplashLoaded(true);
	}, []);

	return (
		<View
			style={ { ...styles.background, ...{ backgroundColor: darkMode ? "#232323" : "#f7f7f7" } } }
		>
			<TouchableHighlight underlayColor={ "transparent" }>
				<Image
					style={ styles.image }
					source={ heroImage }
				/>
			</TouchableHighlight>
			<Text style={ { ...styles.screenName, ...{ color: darkMode ? "white" : "black" } } }>
				How It Works
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	background: {
		flex: 1,
		justifyContent: "center",
	},
	image: {
		marginTop: 20,
		width: 50,
		aspectRatio: 1,
		borderRadius: "50%",
		resizeMode: "contain",
		alignSelf: "center",
	},
	screenName: {
		margin: 16,
		alignSelf: "center",
		fontWeight: "500",
	},
});
