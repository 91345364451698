import { Grid } from "@mui/material";
import { ScreenComponent } from "../models/enums/ScreenComponents";
import { Product } from "../models/DB";
import { Image, Text, View } from "react-native";
import { StyleSheet } from "react-native";
import { getBadgeText } from "../utils/customFunctions";
import { FavoriteOutlined } from "@mui/icons-material";

interface ProductItemProps {
    product: Product;
    navigation: any;
}

export const ProductItem = ({ product, navigation }: ProductItemProps) => {

    const handleProductPress = () => {
        navigation.navigate(ScreenComponent.Product, { id: product.id });
    };

    const isOnSale = product.discount > 0;
    const inStock = product.inStock;
    const discountAmount = product.discount;
    const isWishlistItem = product.isWishlistItem ?? false;


    const { text, textColor, backgroundColor } = getBadgeText({ inStock, isOnSale, discountAmount });

    return (
        <Grid
            item
            xs={5.35}
            sm={3.5}
            lg={2.5}
            sx={{
                backgroundColor: "white",
                flexDirection: "column",
            }}
            boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.1)"}
            borderColor={"#f1f1f1"}
            borderRadius={4}
            padding={1.5}
            onClick={handleProductPress}
        >
            <Grid
                item
                marginBottom={4}
                style={{ position: "relative" }}
            >
                <Image
                    source={
                        product.images["thumbnail"]?.url
                            ? { uri: product.images["thumbnail"].url }
                            : require("../assets/no-image.png")
                    }
                    style={{
                        borderRadius: 8,
                        width: "100%",
                        resizeMode: "cover",
                        padding: "50%",
                        aspectRatio: 1 / 1,
                        overflow: "hidden",
                    }}
                />
                {
                    text && 
                    <Grid
                        item
                        justifyContent={"center"}
                        alignItems={"center"}
                        boxShadow={"0px 2px 2px rgba(0, 0, 0, 0.1)"}
                        sx={{
                            position: "absolute",
                            top: 12,
                            right: 0,
                            backgroundColor: backgroundColor,
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                            padding: 1.5,
                            paddingY: 0.5,
                        }}
                    >
                        <Text style={{
                            color: textColor,
                            fontSize: 10,
                            fontWeight: "bold",
                            fontFamily: "primaryFont",
                            letterSpacing: 0.5,
                        }}>
                            {text}
                        </Text>
                    </Grid>
                }
                {
                    isWishlistItem &&
                    <Grid
                        item
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            position: "absolute",
                            top: 12,
                            left: 0,
                        }}
                    >
                        <FavoriteOutlined color="error" />
                        </Grid>
                }
            </Grid>
            <Grid marginTop="auto" style={{ position: "relative" }}>
                <Grid item>
                    <Text style={styles.name}>{product.name}</Text>
                </Grid>
                <Grid item marginTop={0.5}>
                    {isOnSale && inStock ? (
                        <>
                            <Text style={styles.discountedPrice}>{`R ${(product.price - product.discount).toFixed(2)}  `}</Text>
                            <Text style={styles.strikeThroughPrice}>{`R ${product.price}`}</Text>
                        </>
                    ) : (
                        <Text style={styles.price}>R {product.price}</Text>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

const styles = StyleSheet.create({
    name: {
        fontSize: 16,
        fontWeight: "bold",
        fontFamily: "primaryFont",
        letterSpacing: 0.5,
        color: "black",
    },
    price: {
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: "primaryFont",
        letterSpacing: 0.5,
        color: "gray",
    },
    strikeThroughPrice: {
        fontSize: 12,
        fontWeight: "bold",
        fontFamily: "primaryFont",
        letterSpacing: 0.5,
        color: "lightgrey",
        textDecorationLine: "line-through",
    },
    discountedPrice: {
        fontSize: 14,
        fontWeight: "bold",
        fontFamily: "primaryFont",
        letterSpacing: 0.5,
        color: "green",
    },
});
