import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { Animated, Image, StyleSheet, Text, View } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { cartStore } from "../../../app/stores/Cart";
import { navigationStore } from "../../../app/stores/Navigation";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { NavBarItem } from "./NavBarItem";
import { useScreenComponent } from "../../../app/hooks/custom/useScreenComponent";
import { DefaultRoute } from "../../../models/enums/DefaultRoute";
import { ProfileIcon } from "../../ProfileIcon";
import { userStore } from "../../../app/stores/User";
import { AppHeaderName, AppLogo } from "../../../config/constants";

interface NavBarProps {
	screens: ScreenComponent[];
	align?: "center" | "flex-start" | "flex-end";
}

export const NavBar = ({ align = "center" }: NavBarProps) => {
	const { navigateToScreen } = useScreenComponent();

	const darkMode = appConfigStore((state) => state.darkMode);
	const { showNavigationBar, navBarItemList, updateDefaultRoute } = navigationStore((state) => state);
	const { user } = userStore((state) => state);

	const cartItems = cartStore((state) => state.cartItems);
	const [navHeightAnimation] = useState(new Animated.Value(0));
	const navHeight = 85;

	useEffect(() => {
		if (showNavigationBar) {
			slideDown();
		} else {
			slideUp();
		}

	}, [showNavigationBar]);


	const slideDown = () => {
		Animated.timing(navHeightAnimation, {
			toValue: navHeight,
			duration: 750,
			delay: 250,
			useNativeDriver: false,
		}).start();
	};

	const slideUp = () => {
		Animated.timing(navHeightAnimation, {
			toValue: 0,
			duration: 750,
			delay: 250,
			useNativeDriver: false,
		}).start();
	};

	return (
		<Animated.View
			style={{
				flex: 1,
				height: navHeightAnimation,
				maxHeight: navHeightAnimation,
				flexDirection: "row",
				justifyContent: align,
				paddingHorizontal: 40,
				backgroundColor: darkMode ? "#1c1c1c" : "white",
				// Include marginTop to push the view down from top when animation starts
				marginTop: navHeightAnimation.interpolate({
					inputRange: [0, navHeight],
					outputRange: [-navHeight, 0],
				}),
				zIndex: 1,
				elevation: 1,
				shadowRadius: 8,
				shadowOpacity: 0.1,
				shadowOffset: {
					width: 0,
					height: 6,
				},
			}}
		>
			<View style={{ flexDirection: "row" }}>
				<Image
					style={styles.image}
					source={require(`../../../assets/${AppLogo}`)}
				/>
				<Text style={{ ...styles.title, ...{ color: darkMode ? "white" : "black", fontWeight: "bold" } }}>
					{AppHeaderName}
				</Text>
			</View>
			<Divider
				color={darkMode ? "white" : "black"}
				orientation="vertical"
				style={{
					width: 1,
					marginLeft: 22,
					marginRight: 14,
					height: 16,
					alignSelf: "center",
				}}
			/>
			<View
				style={{
					flex: 1,
					flexDirection: "row",
					marginLeft: 40,
					alignItems: "center",
					justifyContent: align,
				}}
			>
				{
					navBarItemList.map((text, index) => {
						return (
							<NavBarItem
								key={index}
								screen={text}
							/>
						);
					})
				}
			</View>
			<View style={{ flexDirection: "row", gap: 14 }}>
				{/* {
					!user.authenticated &&
					<BecomeMember
						darkMode={darkMode}
						onClick={() => {
							updateDefaultRoute(DefaultRoute.Register);
							navigateToScreen(ScreenComponent.Register);
						}}
					/>
				} */}
				<ProfileIcon
					isAuthenticated={user.authenticated}
					firstName={user?.info?.user_metadata?.firstName ?? ""}
					darkMode={darkMode}
					role={user.role}
					onClick={() => {
						updateDefaultRoute(DefaultRoute.Login);
						navigateToScreen(ScreenComponent.Login);
					}}
				/>
				{/* <CartIcon
					darkMode={darkMode}
					cartItems={cartItems}
					onClick={() => navigateToScreen(ScreenComponent.Cart)}
				/> */}
			</View>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	image: {
		width: 50,
		aspectRatio: 1,
		resizeMode: "contain",
		alignSelf: "center",
		borderRadius: 25,
	},
	title: {
		marginLeft: 12,
		alignSelf: "center",
		fontSize: 20,
		fontWeight: "900",
		fontFamily: "primaryFont",
		letterSpacing: 0.5,
		color: "white",
	},
});
