import { create } from "zustand";
import { PortalScreenComponent, ScreenComponent } from "../../models/enums/ScreenComponents";
import { DefaultRoute } from "../../models/enums/DefaultRoute";
import { DefaultPortalRoute } from "../../models/enums/DefaultPortalRoute";

type State = {
	splashLoaded: boolean;
	showNavigationBar: boolean;
	splashVideoLoaded: boolean;
	defaultAuthRoute: DefaultRoute;
	defaultPortalAuthRoute: DefaultPortalRoute;
	currentScreen: ScreenComponent;
	portalCurrentScreen: PortalScreenComponent;
	screenList: ScreenComponent[];
	navBarItemList: ScreenComponent[];
	drawerItemList: ScreenComponent[];
	portalItemList: PortalScreenComponent[];
};

type Action = {
	updateShowNavigationBar: (data: State["showNavigationBar"]) => void;
	updateDefaultRoute: (data: State["defaultAuthRoute"]) => void;
	updateDefaultPortalAuthRoute: (data: State["defaultPortalAuthRoute"]) => void;
	updateCurrentScreen: (data: State["currentScreen"]) => void;
	updatePortalCurrentScreen: (data: State["portalCurrentScreen"]) => void;
	updatescreenList: (data: State["screenList"]) => void;
	updateSplashLoaded: (data: State["splashLoaded"]) => void;
	updateSplashVideoLoaded: (data: State["splashVideoLoaded"]) => void;
};

export const navigationStore = create<State & Action>(
	(set) => ({
		splashLoaded: true,
		splashVideoLoaded: false,
		showNavigationBar: true,
		defaultAuthRoute: DefaultRoute.Login,
		defaultPortalAuthRoute: DefaultPortalRoute.Login,
		currentScreen: ScreenComponent.Shop,
		portalCurrentScreen: PortalScreenComponent.Products,
		portalItemList: [
			PortalScreenComponent.Products,
			PortalScreenComponent.Wishlist,
		],
		screenList: [
			ScreenComponent.Splash,
			ScreenComponent.Shop,
			ScreenComponent.Product,
			// ScreenComponent.HowItWorks,
			ScreenComponent.Bookings,
			// ScreenComponent.Orders,
			// ScreenComponent.Contact,
			// ScreenComponent.Cart,
			ScreenComponent.Register,
			ScreenComponent.Login,
			ScreenComponent.Portal,
		],
		navBarItemList: [
			ScreenComponent.Shop, 
			ScreenComponent.Bookings
		],
		drawerItemList: [
			ScreenComponent.Shop, 
			ScreenComponent.Bookings,
			// ScreenComponent.Orders, 
			// ScreenComponent.Cart,
			ScreenComponent.Login,
		],

		updateSplashLoaded: (data) =>
			set(() => ({
				splashLoaded: data,
			})),

		updateSplashVideoLoaded: (data) =>
			set(() => ({
				splashVideoLoaded: data,
			})),

		updatescreenList: (data) =>
			set(() => ({
				screenList: data,
			})),

		updateCurrentScreen: (data) =>
			set(() => ({
				currentScreen: data,
			})),

		updatePortalCurrentScreen: (data) =>
			set(() => ({
				portalCurrentScreen: data,
			})),

		updateDefaultRoute: (data) =>
			set(() => ({
				defaultAuthRoute: data,
			})),

		updateDefaultPortalAuthRoute: (data) =>
			set(() => ({
				defaultPortalAuthRoute: data,
			})),

		updateShowNavigationBar: (data) =>
			set(() => ({
				showNavigationBar: data,
			})),
	})
);
