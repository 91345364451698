import { View, StyleSheet } from "react-native";
import { PortalLogin } from "./PortalLogin";
// import { PortalRegister } from "./PortalRegister";
// import { navigationStore } from "../../../app/stores/Navigation";
// import { DefaultPortalRoute } from "../../../models/enums/DefaultPortalRoute";

export const PortalAuthStack = () => {
	// const { defaultPortalAuthRoute } = navigationStore((state) => state);

	const AuthScreens = () => {
		// return defaultPortalAuthRoute === DefaultPortalRoute.Login ? <PortalLogin /> : <PortalRegister />;
		return <PortalLogin />;
	};

	return (
		<View style={styles.authContainer}>
			<AuthScreens />
		</View>
	);
};

const styles = StyleSheet.create({
	authContainer: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
	},
});
