import React, { useEffect, useState } from "react";
import { Text, StyleSheet, Animated } from "react-native";
import { BasicButton } from "../../../components/BasicButton";
import { BasicTextField } from "../../../components/BasicTextField";
import { Container, Box, Grid } from "@mui/material";
import PortalService from "../../../services/supabase/PortalService";
import * as _ from "lodash";
import { stateErrorColor } from "../../../config/constants";

export const PortalLogin = () => {
	const { signInWithEmailAndPassword } = PortalService();
	const [fadeAnimation] = useState(new Animated.Value(0));
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	useEffect(() => {
		fadeIn();
	}, []);

	const fadeIn = () => {
		return Animated.timing(fadeAnimation, {
			toValue: 1,
			duration: 750,
			useNativeDriver: false,
		}).start();
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		setErrorMessage(null);
		const data = new FormData(event.currentTarget);
		const email = data.get("email") as string;
		const password = data.get("password") as string;

		const { success, message } = await signInWithEmailAndPassword(email, password);
		setLoading(false);
		if (!success) setErrorMessage(message);
	};

	return (
		<Animated.View
			style={{
				opacity: fadeAnimation,
			}}
		>
			<Container
				component={"main"}
				maxWidth={"xs"}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						backgroundColor: "white",
						borderRadius: 2,
					}}
					boxShadow={"0px 8px 10px rgba(0, 0, 0, 0.1)"}
				>
					<Box
						component={"form"}
						onSubmit={handleSubmit}
						sx={{ m: 8 }}
					>
						<Grid
							container
							mb={2}
							flexDirection={"column"}
							spacing={1}
						>
							<Grid item>
								<Text style={styles.title}>Admin Portal</Text>
							</Grid>
							<Grid
								item
								sx={{ mb: 2 }}
							>
								<Text style={styles.subtitle}>Restricted to authorized users only</Text>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								xs={12}
							>
								<BasicTextField
									required
									fullWidth
									id={"email"}
									name={"email"}
									size={"small"}
									label={"Email"}
									margin={"normal"}
									inputProps={{ style: { color: "black" } }}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<BasicTextField
									required
									fullWidth
									id={"password"}
									name={"password"}
									size={"small"}
									label={"Password"}
									type={"password"}
									inputProps={{ style: { color: "black" } }}
								/>
							</Grid>
							<Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
								<Text style={styles.errorMessage}>{errorMessage}</Text>
							</Grid>
						</Grid>
						<Grid
							container
							mt={2}
							direction={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							spacing={2}
						>
							<Grid item>
								<BasicButton
									title={"Login"}
									type={"submit"}
									style={{ width: 130, backgroundColor: "black" }}
									isLoading={loading}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	title: {
		fontSize: 22,
		fontWeight: "600",
		fontFamily: "primaryFont",
		marginBottom: 4,
		textAlign: "center",
		color: "black",
	},
	subtitle: {
		fontSize: 16,
		fontWeight: "400",
		fontFamily: "primaryFont",
		textAlign: "center",
		color: "black",
	},
	errorMessage: {
		color: stateErrorColor,
		textAlign: "center",
		marginTop: 10,
		fontFamily: "primaryFont",
	},
});
