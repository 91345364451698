import axios, { HttpStatusCode } from "axios";
import { Session, User } from "@supabase/supabase-js";
import { ResponseModel } from "../../models/Response";
import { ApiEndpoints } from "../../config/constants";
import useAuthSession from "../../app/hooks/custom/useAuthSession";
import { AccessLevel } from "../../models/enums/AccessLevel";
import { OrderDetails, Product } from "../../models/DB";
import { productStore } from "../../app/stores/Product";

const PortalService = () => {
    const { updateProducts } = productStore((state) => state);
    const { saveSession } = useAuthSession();

    // Product Management Endpoints

    const getAllProducts = async () => {
		try {
			const response = await axios.get<ResponseModel<Product[]>>(ApiEndpoints.Products.GetAll);
			const { data, statusCode, errorMessage } = response.data;

			if (statusCode === HttpStatusCode.Ok && data) {
				if(data.length > 0) {
					updateProducts(data);
				}
				return;
			}
			
			console.log("Error fetching products:", errorMessage);
		} catch (error) {
			console.log("Error fetching products:", error);
		}
	};

    const createProduct = async (product: Product, files: File[]) => {
        try {
            const formData = new FormData();
            formData.append('product', JSON.stringify(product));
            files.forEach((file) => {
				formData.append(`files`, file);
            });
			
            const response = await axios.post<ResponseModel<Product>>(ApiEndpoints.Admin.CreateProduct, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const { data, statusCode, errorMessage } = response.data;
            if (statusCode === HttpStatusCode.Ok && data) {
                return data;
            }

            console.error("Error creating product:", errorMessage);
            return null;
        } catch (error) {
            console.log("Error creating product:", error);
            return null;
        }
    };

	const updateProduct = async (product: Product, files: File[]) => {
		try {
			const formData = new FormData();
			formData.append('product', JSON.stringify(product));
			files.forEach((file) => {
				formData.append(`files`, file);
			});

			const response = await axios.put<ResponseModel<Product>>(ApiEndpoints.Admin.UpdateProduct, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});

			const { data, statusCode, errorMessage } = response.data;
			if (statusCode === HttpStatusCode.Ok && data) {
				return data;
			}

			console.error("Error updating product:", errorMessage);
			return null;
		} catch (error) {
			console.log("Error updating product:", error);
			return null;
		}
	};

	const deleteProduct = async (id: string, code: string) => {
		try {
			const response = await axios.delete<ResponseModel<Product>>(ApiEndpoints.Admin.DeleteProduct(id, code));
			const { data, statusCode, errorMessage } = response.data;

			if (statusCode === HttpStatusCode.Ok && data) {
				return data;
			}
			console.error("Error deleting product:", errorMessage);
			return null;
		} catch (error) {
			console.log("Error deleting product:", error);
			return null;
		}
	};
    
    // Order Management Endpoints

    const getAllOrders = async () => {
        try {
            const response = await axios.get(ApiEndpoints.Admin.GetAllOrders);
            const { data, statusCode, errorMessage } = response.data;

            if (statusCode === HttpStatusCode.Ok) {
                return data;
            }

            console.log("Error fetching orders: ", errorMessage);
            return null;
        } catch (error) {
            console.error("Error fetching orders:", error);
            return null;
        }
    };

    const updateOrder = async (order: OrderDetails) => {
        try {
            const response = await axios.put<ResponseModel<OrderDetails>>(ApiEndpoints.Admin.UpdateOrder, order);
            const { data, statusCode, errorMessage } = response.data;

            if (statusCode === HttpStatusCode.Ok && data) {
                return data;
            }

            console.error("Error updating order:", errorMessage);
            return null;
        } catch (error) {
            console.log("Error updating order:", error);
            return null;
        }
    }

    // Admin Management Endpoints

    const signInWithEmailAndPassword = async (email: string, password: string) => {
        try {
            const { data: { data, statusCode, errorMessage } } = await axios.post<ResponseModel<{ user: User, session: Session }>>(
                ApiEndpoints.Admin.SignIn, {
                    email: email,
                    password: password,
                    withCredentials: true,
                }
            );

            if (data?.user && statusCode === HttpStatusCode.Ok) {
                saveSession(data.user, data.session);
                return {
                    success: true,
                    message: "Signed in successfully",
                };
            }

            return {
                success: false,
                message: errorMessage ?? "We're having trouble signing you in. Please try again later.",
            };
        } catch (error) {
            console.error("Error: ", error);
            return {
                success: false,
                message: "Error signing in: " + error,
            };
        }
    };

    const createStandardUser = async (email: string, password: string, phone: string, firstName: string, lastName: string, accessLevel: AccessLevel) => {
        try {
            const { data: { data, statusCode, errorMessage } } = await axios.post<ResponseModel<{ user: User, session: Session }>>(
                ApiEndpoints.Admin.SignUp, {
                email: email,
                password: password,
                phone: phone,
                firstName: firstName,
                lastName: lastName,
                role: accessLevel,
            });
            
            if (data?.user && statusCode === HttpStatusCode.Created) {
                return {
                    success: true,
                    message: "Done! Please check your email for a verification link.",
                };
            }

            return {
                success: false,
                message: errorMessage ?? "We're having trouble signing you up. Please try again later.",
            };
        } catch (error) {
            console.error("Error: ", error);
            return {
                success: false,
                message: "Error signing up: " + error,
            };
        }
    };
    
    return {
        getAllProducts,
        createProduct,
        updateProduct,
        deleteProduct,
        getAllOrders,
        updateOrder,
        signInWithEmailAndPassword,
        createStandardUser
    };
};

export default PortalService;