import { navigationStore } from "../../../app/stores/Navigation";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { TouchableHighlight } from "react-native-gesture-handler";
import { Text } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { useScreenComponent } from "../../../app/hooks/custom/useScreenComponent";
import { primaryColor } from "../../../config/constants";

interface NavBarItem {
	screen: ScreenComponent;
}

export const NavBarItem = ({ screen }: NavBarItem) => {
	const currentScreen = navigationStore((state) => state.currentScreen);
	const darkMode = appConfigStore((state) => state.darkMode);
	const isCurrentScreen = currentScreen === screen;
	const { navigateToScreen } = useScreenComponent();

	return (
		<TouchableHighlight
			style={{
				minHeight: 32,
				paddingHorizontal: 10,
				marginHorizontal: 12,
				paddingBottom: 4,
				justifyContent: "center",
				borderBottomColor: isCurrentScreen ? (darkMode ? primaryColor : primaryColor) : "transparent",
				borderBottomWidth: 3,
				// backgroundColor: isCurrentScreen ? (darkMode ? "#e9e9e9" : "#dbdbdb") : "transparent",
			}}
			underlayColor="transparent"
			onPress={() => {
				navigateToScreen(screen);
			}}
		>
			<Text
				style={{
					fontSize: 16,
					letterSpacing: 0.5,
					fontWeight: "900",
					fontFamily: "primaryFont",
					textAlign: "center",
					color: darkMode ? "white" : "black",
				}}
			>
				{screen.replaceAll("_", " ").toString()}
			</Text>
		</TouchableHighlight>
	);
};
