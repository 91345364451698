import { Shop } from "../../../screens/Main/Shop/Shop";
import { ProductDetail } from "../../../screens/Main/ProductDetail";
import { HowItWorks } from "../../../screens/Main/HowItWorks";
import { Orders } from "../../../screens/Main/Orders";
import { ContactUs } from "../../../screens/Main/ContactUs";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { Splash } from "../../../screens/Main/Splash";
import { Cart } from "../../../screens/Main/Cart";
import { useLinkTo } from "@react-navigation/native";
import { navigationStore } from "../../stores/Navigation";
import { PortalRoutes } from "../../../screens/Portal/PortalRoutes";
import { Register } from "../../../screens/Authentication/Register";
import { Login } from "../../../screens/Authentication/Login";
import { Bookings } from "../../../screens/Main/Bookings";


export const useScreenComponent = () => {
	const linkTo = useLinkTo();
	const { updateCurrentScreen } = navigationStore((state) => state);

	const navigateToScreen = (screen: ScreenComponent, params?: Object) => {
		const screenPath = screen.toLowerCase().replaceAll("_", "") as never
		const screenParams = params ? Object.entries(params).map(([key, value]) => `${key}=${value}`).join("&") : "";

		linkTo(`/${screenPath}?${screenParams}`);
		updateCurrentScreen(screen as ScreenComponent);
	};

	// function to return screen component based on screen enum
	const getScreenComponent = (screen: ScreenComponent) => {
		switch (screen) {
			case ScreenComponent.Splash:
				return Splash;
			case ScreenComponent.HowItWorks:
				return HowItWorks;
			case ScreenComponent.Shop:
				return Shop;
			case ScreenComponent.Product:
				return ProductDetail;
			case ScreenComponent.Orders:
				return Orders;
			case ScreenComponent.Contact:
				return ContactUs;
			case ScreenComponent.Cart:
				return Cart;
			case ScreenComponent.Register:
				return Register;
			case ScreenComponent.Login:
				return Login;
			case ScreenComponent.Portal:
				return PortalRoutes;
			case ScreenComponent.Bookings:
				return Bookings;
			default:
				throw new Error(`Unsupported screen component: ${screen}`);
		}
	}

	return { getScreenComponent, navigateToScreen };
};
