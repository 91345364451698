import React, { useState } from "react";
import { Box, Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Fade, SelectChangeEvent } from "@mui/material";
import { DeliveryDetails, ShippingDetails } from "../../../models/DB";
import { BasicButton } from "../../../components/BasicButton";
import { cities, ShippingFormCity, states } from "../../../models/ShippingFormModel";

interface ShippingFormProps {
	onNext: (details: ShippingDetails) => void;
	onBack: () => void;
}

export const ShippingForm = ({ onNext, onBack }: ShippingFormProps) => {
	const [addressLine1, setAddress1] = useState("");
	const [addressLine2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [region, setRegion] = useState("");
	const [zip, setZip] = useState("");
	const [suburb, setSuburb] = useState("");
	const [country, setCountry] = useState("South Africa");
	const [extraCharge, setExtraCharge] = useState(0);
	const [selectedCity, setSelectedCity] = useState<ShippingFormCity | null>(null);
	const [showSameDayDelivery, setShowSameDayDelivery] = useState(false);
	const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
	const [deliveryOption, setDeliveryOption] = useState<"standard" | "sameDay" | null>(null);

	const handleCityChange = (event: SelectChangeEvent<string>) => {
		const cityName = event.target.value;
		const city = cities.find(c => c.name === cityName) || null;
		setSelectedCity(city);
		setCity(cityName);
		setShowSameDayDelivery(!!city?.sameDayDelivery);
		setShowDeliveryOptions(!!city?.standardDelivery || !!city?.sameDayDelivery);
		if (!city?.sameDayDelivery) {
			setDeliveryOption(null);
			setSuburb("");
		}
		if (!city?.standardDelivery && !city?.sameDayDelivery) {
			setDeliveryOption(null);
			setExtraCharge(0);
		}
	};

	const handleDeliveryOptionChange = (option: "standard" | "sameDay") => {
		setDeliveryOption(option);
		setExtraCharge(option === "sameDay" ? 150 : 100);
	};

	const handleSuburbChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSuburb(event.target.value as string);
	};

	const handleAddress1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAddress1(event.target.value);
	};

	const handleAddress2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAddress2(event.target.value);
	};

	const handleRegionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setRegion(event.target.value as string);
	};

	const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setZip(event.target.value);
	};

	const handleNext = () => {
		console.log("handle Next");
		if (!addressLine1 || !city || !region || !zip || !country || deliveryOption === null) {
			alert("Please fill in all the required fields and select a delivery option.");
			return;
		}

		if (deliveryOption === "sameDay" && !suburb) {
			alert("Please select a suburb for same-day delivery.");
			return;
		}

		console.log("passed validation");

		const details: ShippingDetails = {
			addressLine1,
			addressLine2,
			city,
			region,
			zip,
			country
		};

		const deliveryDetails: DeliveryDetails = {
			deliveryType: deliveryOption,
			deliveryFee: extraCharge,
			suburb: deliveryOption === "sameDay" ? suburb : "",
		};

		onNext({ ...details, ...deliveryDetails });
	};

	return (
		<Box component="form" onSubmit={handleNext}>
			<Typography
				variant="h6"
				gutterBottom
				mt={2}
				mb={4}
			>
				Delivery Details
			</Typography>
			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					xs={12}
				>
					<FormControl fullWidth variant="outlined" required>
						<InputLabel id="city-label">City</InputLabel>
						<Select
							labelId="city-label"
							id="city"
							value={city}
							onChange={handleCityChange}
							autoComplete="shipping address-level2"
						>
							{cities.map((city) => (
								<MenuItem key={city.name} value={city.name}>
									{city.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Fade in={showDeliveryOptions} timeout={600}>
					<Grid
						container
						item
						xs={12}
						spacing={2}
						alignItems="center"
						my={1}
						sx={{ display: showDeliveryOptions ? "flex" : "none" }}
					>
						{showSameDayDelivery && (
							<Grid item xs={12} sm={6}>
								<FormControlLabel
									control={
										<Checkbox
											value={"sameDay"}
											checked={deliveryOption === "sameDay"}
											onChange={() => handleDeliveryOptionChange("sameDay")}
											name="sameDayDelivery"
											color="primary"
										/>
									}
									label={
										<>
											<Typography color={"green"} fontWeight={"bold"} variant="body1">Same Day Delivery (R150)</Typography>
											<Typography color={"grey"} variant="body2">To supported areas only</Typography>
										</>
									}
								/>
							</Grid>
						)}
						{selectedCity?.standardDelivery && (
							<Grid item xs={12} sm={6}>
								<FormControlLabel
									required={selectedCity?.standardDelivery && deliveryOption === null}
									control={
										<Checkbox
											value={"standard"}
											checked={deliveryOption === "standard"}
											onChange={() => handleDeliveryOptionChange("standard")}
											name="standardDelivery"
											color="primary"
										/>
									}
									label={
										<>
											<Typography fontWeight={"bold"} variant="body1">Standard Delivery (R100)</Typography>
											<Typography color={"grey"} variant="body2">Estimated 2-3 days</Typography>
										</>
									}
								/>
							</Grid>
						)}
						<Fade in={deliveryOption === "sameDay"} timeout={600}>
							<Grid
								item
								xs={12}
								sm={6}
								mt={1}
								sx={{ display: deliveryOption === "sameDay" ? "flex" : "none" }}
							>
								<FormControl fullWidth variant="outlined" required={deliveryOption === "sameDay"}>
									<InputLabel id="suburb-label">Suburb</InputLabel>
									<Select
										labelId="suburb-label"
										id="suburb"
										value={suburb}
										onChange={handleSuburbChange}
										label="Suburb"
									>
										<MenuItem value="" disabled>
											Select a suburb
										</MenuItem>
										{selectedCity?.suburbs.map((suburb) => (
											<MenuItem
												key={suburb.name}
												value={suburb.name}
												disabled={!suburb.selectable}
											>
												{suburb.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Fade>
					</Grid>
				</Fade>
				<Grid
					item
					xs={12}
				>
					<TextField
						required
						id="address1"
						name="address1"
						label="Address line 1"
						fullWidth
						variant="outlined"
						onChange={handleAddress1Change}
						autoComplete="shipping street-address"
					/>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<TextField
						id="address2"
						name="address2"
						label="Address line 2"
						fullWidth
						variant="outlined"
						onChange={handleAddress2Change}
						autoComplete="shipping address-line2"
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
				>
					<FormControl fullWidth variant="outlined" required>
						<InputLabel id="state-label">State/Province/Region</InputLabel>
						<Select
							labelId="state-label"
							id="state"
							value={region}
							onChange={handleRegionChange}
							autoComplete="shipping address-level1"
						>
							{
								states.filter(states => states.selectable).map(state => (
									<MenuItem key={state.name} value={state.name}>
										{state.name}
									</MenuItem>
								))
							}
						</Select>
					</FormControl>
				</Grid>
				<Grid
					item
					xs={12}
					sm={6}
				>
					<TextField
						required
						id="zip"
						name="zip"
						label="Zip / Postal code"
						fullWidth
						variant="outlined"
						onChange={handleZipChange}
						autoComplete="shipping postal-code"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="country"
						name="country"
						label="Country"
						fullWidth
						variant="outlined"
						value={"South Africa"}
						disabled
					/>
				</Grid>
			</Grid>
			{/* Buttons to move to the next step or go back */}
			<Grid
				container
				justifyContent="space-between"
				mt={4}
			>
				<BasicButton
					title={"Back"}
					variant="outlined"
					onClick={onBack}
				/>
				<BasicButton
					title={"Next"}
					variant="contained"
					type="submit"
				/>
			</Grid>
		</Box>
	);
};
