import { useEffect, useState } from "react";
import { ActivityIndicator, ScrollView, StyleSheet, Text } from "react-native";
import { Grid, Typography } from "@mui/material";
import { AddShoppingCartOutlined, ArrowBack, CloseOutlined, CloseRounded, Favorite, FavoriteBorderOutlined } from "@mui/icons-material";
import { BackHandler } from "react-native";
import { ProductsService } from "../../services/supabase/ProductsService";
import { Product } from "../../models/OrderDetails";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { cartStore } from "../../app/stores/Cart";
import { userStore } from "../../app/stores/User";
import { BasicButton } from "../../components/BasicButton";
import { primaryColor, stateErrorColor } from "../../config/constants";
import { ImagePreviewer } from "../../components/ImagePreviewer";
import { WishlistService } from "../../services/supabase/WishlistService";
import { checkIfInWishlist } from "../../utils/customFunctions";
import { productStore } from "../../app/stores/Product";

export const ProductDetail = ({ route, navigation }) => {
	const [selectedVariant, setSelectedVariant] = useState<string>();
	const [loading, setLoading] = useState(true);
	const [addToWishlistLoading, setAddToWishlistLoading] = useState(false);
	const [removeFromWishlistLoading, setRemoveFromWishlistLoading] = useState(false);
	const [product, setProduct] = useState<Product>();
	const [isInWishlist, setIsInWishlist] = useState(false);
	const productsService = ProductsService();
	const { getWishlist, addToWishlist, removeFromWishlist } = WishlistService();

	const { wishlist } = productStore((state) => state);
	const { addToCart, updateCartItemQuantity, removeFromCart, cartItems } = cartStore((state) => state);
	const { authenticated, info: { id: userId, email, user_metadata } } = userStore((state) => state.user);


	const [userDetails] = useState({
		firstName: user_metadata?.firstName,
		lastName: user_metadata?.lastName
	})

	const isVariantInCart = product && cartItems.some(item => item.product.code === product.code && item.selectedVariant === selectedVariant);
	const cartQuantity = isVariantInCart ? cartItems.find(item => item.product.code === product.code && item.selectedVariant === selectedVariant)?.quantity : 0;
	const params = route.params;

	useEffect(() => {
		const backAction = () => {
			navigation.navigate(ScreenComponent.Shop);
			return true;
		};
		const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction);
		return () => backHandler.remove();
	}, [navigation]);

	useEffect(() => {
		if (params && params.id)
			fetchProductById(params.id)
		else
			setLoading(false);
	}, [params]);

	useEffect(() => {
		if (authenticated && product && email && wishlist.length === 0) {
			setTimeout(() => {
				getWishlist(email);
				console.log("wishlist requested")
			}, 1000);
		}

		if (authenticated && product && wishlist.length > 0) {
			setIsInWishlist(checkIfInWishlist(product.code, wishlist));
		}
	}, [authenticated, product, email, wishlist]);

	const fetchProductById = async (productId: string) => {
		setLoading(true);
		const product = await productsService.getProductById(productId);
		if (product)
			setProduct(product);

		setLoading(false);
	}

	const handleAddToWishlist = async () => {
		if (authenticated && product && email) {
			setAddToWishlistLoading(true);
			const response = await addToWishlist(product.code, userId, email, userDetails.firstName, userDetails.lastName, product, selectedVariant);
			if (response) setIsInWishlist(true);
			setAddToWishlistLoading(false);
		}
	};

	const handleRemoveFromWishlist = async () => {
		if (authenticated && product && email) {
			setRemoveFromWishlistLoading(true);
			const wishlistItemId = wishlist.find(item => item.product.code === product.code)?.id;
			if (wishlistItemId) {
				const response = await removeFromWishlist(wishlistItemId);
				if (response) setIsInWishlist(false);
			}
			setRemoveFromWishlistLoading(false);
		}
	};

	const handleAddToCart = () => {
		if (authenticated && product) {
			addToCart(product, selectedVariant);
		} else {
			console.log("User not authenticated");
		}
	};

	const handleUpdateCartQuantity = (quantityChange) => {
		if (authenticated && product) {
			const cartItem = cartItems.find(item => item.product.code === product.code && item.selectedVariant === selectedVariant);
			if (cartItem) {
				updateCartItemQuantity(product.code, cartItem.quantity + quantityChange, selectedVariant);
			}
		} else {
			console.log("User not authenticated");
			// Optionally, navigate to login screen or show a message
		}
	};

	const handleRemoveFromCart = () => {
		if (authenticated && product) {
			removeFromCart(product.code, selectedVariant);
		} else {
			console.log("User not authenticated");
			// Optionally, navigate to login screen or show a message
		}
	};

	const QuantityController = ({
		isVariantInCart,
		cartQuantity,
		handleRemoveFromCart,
		handleUpdateCartQuantity,
		handleAddToCart,
	}) => (
		<Grid mt={8}>
			{authenticated ? (
				isVariantInCart ? (
					<Grid>
						<Grid mb={2}>
							<Typography variant="body2" color={"orange"} fontWeight={"bold"} fontFamily={"primaryFont"} letterSpacing={1}>
								Product already added. You can still update the quantity.
							</Typography>
						</Grid>
						<Grid style={{ flexDirection: "row", alignItems: "center" }}>
							{cartQuantity === 1 ? (
								<BasicButton
									variant="contained"
									title="Remove"
									onClick={handleRemoveFromCart}
									sx={{
										paddingX: { xs: 3, md: 5 },
										backgroundColor: stateErrorColor,
										width: 100,
									}}
								/>
							) : (
								<BasicButton
									variant="contained"
									title="-"
									onClick={() => handleUpdateCartQuantity(-1)}
									disabled={cartQuantity <= 1}
									style={{
										fontSize: 18,
									}}
									sx={{
										paddingX: { xs: 3, md: 5 },
										width: 100,
									}}
								/>
							)}
							<Text
								style={{
									marginHorizontal: 22,
									borderWidth: 1,
									borderRadius: 8,
									paddingHorizontal: 18,
									paddingVertical: 9,
									borderColor: "#c2c2c2",
									fontFamily: "primaryFont", // Added font family here
								}}
							>
								{cartQuantity}
							</Text>
							<BasicButton
								variant="contained"
								title="+"
								onClick={() => handleUpdateCartQuantity(1)}
								disabled={!!(product?.variantType && !selectedVariant)}
								style={{
									fontSize: 18,
								}}
								sx={{
									paddingX: { xs: 3, md: 5 },
									width: 100,
								}}
							/>
						</Grid>
					</Grid>
				) : (
					<BasicButton
						variant="contained"
						title="Add to Cart"
						onClick={handleAddToCart}
						disabled={!!(product?.variantType && !selectedVariant)}
						sx={{
							paddingX: 5,
							paddingY: 1.5,
							fontSize: 12,
						}}
							style={{
								backgroundColor: selectedVariant ?
									primaryColor : "lightgrey",
							}}
						startIcon={<AddShoppingCartOutlined />}
					/>
				)
			) : (
				<Typography variant="body2" color={"grey"} fontWeight={"bold"} fontFamily={"primaryFont"} letterSpacing={1}>
					Please log in to add items to the cart.
				</Typography>
			)}
		</Grid>
	);

	const AddToWishlist = ({
		handleAddToWishlist,
		handleRemoveFromWishlist,
	}) => (
		<Grid mt={4}>
			<Grid >
				<Typography display={!authenticated ? "flex" : "none"} variant="body1" color={"black"} fontWeight={"bold"} fontFamily={"primaryFont"} letterSpacing={1}>
					Please log in to let us know you are interested.
				</Typography>
				<Typography mt={1} sx={{ width: "80%" }} variant="body2" color={"black"} fontFamily={"primaryFont"} letterSpacing={1}>
					{`We are gathering insights on product interest to enhance our inventory decisions and better serve your needs.`}
				</Typography>
				<Typography mt={1} sx={{ width: "80%" }} variant="body2" color={"black"} fontFamily={"primaryFont"} letterSpacing={1}>
					{`Your feedback is much appreciated.`}
				</Typography>
			</Grid>

			<BasicButton
				variant={isInWishlist ? "outlined" : "contained"}
				title="Im Interested"
				onClick={handleAddToWishlist}
				isLoading={addToWishlistLoading}
				disabled={!authenticated || isInWishlist}
				sx={{
					marginTop: 4,
					paddingX: 5,
					paddingY: 1.5,
					fontSize: 12,
				}}
				style={{
					backgroundColor: isInWishlist ? "white" : authenticated ? primaryColor : "lightgrey",
				}}
				startIcon={isInWishlist ? <Favorite /> : <FavoriteBorderOutlined />}
			/>
			{
				isInWishlist &&
				<BasicButton
					variant="text"
					title="Remove"
					isLoading={removeFromWishlistLoading}
					onClick={handleRemoveFromWishlist}
					disabled={!authenticated}
					sx={{
						marginLeft: 2,
						marginTop: 4,
						paddingX: 1,
						paddingY: 1.5,
						fontSize: 12,
					}}
					startIcon={<CloseOutlined />}
				/>
			}
		</Grid>
	);

	return (
		<ScrollView contentContainerStyle={styles.container} stickyHeaderIndices={[0]}>
			<Grid container m={1} mt={2}>
				<BasicButton
					title={"Back"}
					variant="contained"
					startIcon={<ArrowBack />}
					sx={{
						borderRadius: 20,
						fontSize: 12,
						fontFamily: "primaryFont",
						boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)"
					}}
					onClick={() => navigation.navigate(ScreenComponent.Shop)}
				/>
			</Grid>
			{loading ? (
				<Grid container justifyContent="center" alignItems="center" style={styles.spinnerContainer}>
					<ActivityIndicator
						size="large"
						color={primaryColor}
					/>
				</Grid>
			) : (
				product ?
						<Grid container maxWidth={"lg"} alignSelf={"center"} pt={{ xs: 2, md: 4 }} pb={30}>
							<Grid item xs={12} md={6}>
								<ImagePreviewer
									images={product.images as unknown as { [key: string]: { url: string } }}
								/>
							</Grid>
							<Grid
								item
								marginX={{ xs: 2, md: 0 }}
								xs={12}
								md={6}
								mt={{ xs: 10, md: 2 }}>
								<Typography
									variant="h4"
									fontFamily={"primaryFont"}
									letterSpacing={1}
									fontWeight={"bold"}
								>
									{product.name}
								</Typography>
								<Typography
									variant="body2"
									gutterBottom
									color={"grey"}
									fontFamily={"primaryFont"}
									fontWeight={"bold"}
									letterSpacing={1}
								>
									Code: {product.code.substring(0, 6).toUpperCase()}
								</Typography>
								<Typography
									my={2}
									variant="h5"
									gutterBottom
									fontFamily={"primaryFont"}
									letterSpacing={1}
									fontWeight={"bold"}
								>
									{`R ${product.price}`}
								</Typography>
								<Typography
									variant="body1"
									sx={{
										display: product.inStock ? "none" : "flex",
										color: stateErrorColor,
										mb: 2,
										fontFamily: "primaryFont",
										letterSpacing: 1,
										fontWeight: "bold",
									}}
								>
									{"Out of Stock"}
								</Typography>
								{/* {
										product.variantType && (
											<VariantSelector
												variantType={product.variantType}
												variantOptions={product.variantOptions}
												selectedVariant={selectedVariant}
												onSelectVariant={(variant) => setSelectedVariant(variant)}
											/>
										)
									} */}
								{/* <QuantityController
										isVariantInCart={isVariantInCart}
										cartQuantity={cartQuantity}
										handleRemoveFromCart={handleRemoveFromCart}
										handleUpdateCartQuantity={handleUpdateCartQuantity}
										handleAddToCart={handleAddToCart}
									/> */}
								<AddToWishlist
									handleAddToWishlist={handleAddToWishlist}
									handleRemoveFromWishlist={handleRemoveFromWishlist}
								/>
							</Grid>
						</Grid>
					:
					<Grid container maxWidth={"xs"} justifyContent={"center"} pt={{ xs: 2, md: 12 }} pb={30}>
						<Text>Product not found</Text>
					</Grid>
			)}
		</ScrollView >
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 14,
		backgroundColor: "#fcfcfc",
	},
	image: {
		width: "80%",
		aspectRatio: 1 / 1,
		resizeMode: "cover",
		borderRadius: 32,
		alignSelf: "center",
		borderWidth: 2,
		borderColor: "#ececec",
	},
	spinnerContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
});