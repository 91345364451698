import { DefaultTheme, LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { registerRootComponent } from "expo";
import { useFonts } from "expo-font";
import { useEffect, useState } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { HomeStack } from "../navigation/HomeStack";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { appConfigStore } from "./stores/AppConfig";
import { prefix, RootPaths } from "../config/constants";
import useAuthSession from "./hooks/custom/useAuthSession";

const App = () => {
	const darkMode = appConfigStore((state) => state.darkMode);
	const [fadeAnimation] = useState(new Animated.Value(0));
	const { checkSession } = useAuthSession();

	const [fontsLoaded] = useFonts({
		primaryFont: require("../assets/fonts/neuehaas-roman.ttf"),
	});

	useEffect(() => {
		if (fontsLoaded) {
			console.log("App Loaded");
			checkSession();
			fadeIn();
		}
	}, [fontsLoaded]);

	const fadeIn = () => {
		return Animated.timing(fadeAnimation, {
			toValue: 1,
			duration: 1000,
			delay: 0,
			useNativeDriver: false,
		}).start();
	};

	const Container = () => {
		const linking: LinkingOptions<ReactNavigation.RootParamList> = {
			config: {
				screens: RootPaths
			},
			prefixes: [prefix],
		};

		const navigatorTheme = {
			...DefaultTheme,
			colors: {
				...DefaultTheme.colors,
				background: darkMode ? "#212121" : "#f9f9f9",
			},
		};

		return (
			<NavigationContainer
				linking={linking}
				theme={navigatorTheme}
			>
				{fontsLoaded && (
					<Animated.View
						style={{
							...styles.container,
							...{ opacity: fadeAnimation },
						}}
					>
						<HomeStack />
						{/* <AuthStack /> */}
					</Animated.View>
				)}
			</NavigationContainer>
		);
	};

	return (
		<View style={{ width: "100%", height: "100%" }}>
			<Container />
		</View>
	);
};

registerRootComponent(App);
serviceWorkerRegistration.register();

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
	},
	overlay: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: 0,
		backgroundColor: "rgba(0, 0, 0, 0.80)",
	},
	video: {
		position: "absolute",
		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
});
