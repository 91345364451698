import { VAT_PERCENTAGE } from "../config/constants";
import { Wishlist } from "../models/DB";
import { AccessLevel } from "../models/enums/AccessLevel";
import _ from "lodash";

export const formatTimestamp = (timestampString) => {
	const timestamp = new Date(timestampString);
	const options = {
		year: "numeric",
		month: "long", // Abbreviated month
		day: "numeric",
	};
	return timestamp.toLocaleDateString("en-GB", options);
};

export const formatUpdatedTimestamp = (timestampString: string) => {
    const timestamp = new Date(timestampString);
    const now = new Date();
    const differenceInMilliseconds = now.getTime() - timestamp.getTime();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    if (differenceInMilliseconds < oneDayInMilliseconds) {
        const seconds = Math.floor(differenceInMilliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);

        if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    }

	return "-";
};

export const getStatusText = (status) => {
	switch (status) {
		case 1:
			return "Pending Order Confirmation";
		case 2:
			return "Order Confirmed";
		case 3:
			return "In Transit";
		case 4:
			return "Order Delivered";
		default:
			return "Unknown Status";
	}
};

export const getReferenceValue = (reference) => {
	const indexOfFirstDash = reference.indexOf("-");
	if (indexOfFirstDash !== -1) {
		return reference.substring(0, indexOfFirstDash).toString().toUpperCase();
	}
	return reference;
};

export const getDeliveryTypeSetup = (deliveryType) => {
	const text = {
		"sameDay": "Same Day Delivery",
		"standard": "Standard Delivery",
	}

	const backgroundColor = {
		"sameDay": "rgba(0, 128, 0, 0.1)",
		"standard": "rgba(0, 0, 0, 0.1)",
	}

	const textColor = {
		"sameDay": "green",
		"standard": "black",
	}

	const borderColor = {
		"sameDay": "green",
		"standard": "black",
	}

	return {
		text: text[deliveryType],
		backgroundColor: backgroundColor[deliveryType],
		borderColor: borderColor[deliveryType],
		textColor: textColor[deliveryType],
	}
}

export const hasPortalAccess = (role: AccessLevel) => {
	return !_.isEmpty(role) && _.includes([AccessLevel.Moderator.toLowerCase(), AccessLevel.Admin.toLowerCase()], role.toLowerCase());
};

export const getOrderPaymentDetails = (orderItems, deliveryFee) => {
	const baseCost = orderItems.reduce((total, item) => total + item.product.price * item.quantity, 0);
	const vat = baseCost * (VAT_PERCENTAGE / 100);
	const totalCost = baseCost + vat + deliveryFee;
	return { baseCost, vat, totalCost, deliveryFee };
}

export const getBadgeText = ({ inStock, isOnSale, discountAmount }: { inStock: boolean, isOnSale: boolean, discountAmount: number }) => {
	if (!inStock) {
		return {
			text: "Out of Stock",
			textColor: "white",
			backgroundColor: "rgba(128, 128, 128, 0.9)",
		};
	}

	if (isOnSale) {
		return {
			// text: `R${discountAmount} OFF`,
			text: `Sale`,
			textColor: "white",
			backgroundColor: "rgba(205, 0, 0, 0.9)",
		};
	}

	return {
		text: "",
		textColor: "transparent",
		backgroundColor: "transparent",
	};
};

export const checkIfInWishlist = (code: string, wishlist: Wishlist[]) => {
	return wishlist.some(item => item.product.code === code);
};
