import { Grid, Typography } from "@mui/material";
import { OrderItem } from "../models/DB";
import { Image } from "react-native";

interface OrderItemCardProps {
    item: OrderItem;
}

export const OrderItemCard = ({ item }: OrderItemCardProps) => {
    return (
        <Grid
            container
            direction="row"
            marginBottom={2}
            border={2}
            padding={1.5}
            paddingRight={2}
            alignItems={"center"}
            overflow={"visible"}
            boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.1)"}
            borderColor={"#f1f1f1"}
            borderRadius={4}
            sx={{ borderColor: "transparent", backgroundColor: "white" }}
        >
            {/* Product Image */}
            <Grid item>
                <Image
                    source={
                        item.product?.images?.["thumbnail"]?.url
                            ? { uri: item.product.images["thumbnail"].url }
                            : require("../assets/no-image.png")
                    }
                    style={{
                        width: 65,
                        height: 65,
                        resizeMode: "contain",
                        borderRadius: 10,
                    }}
                />
            </Grid>

            {/* Product Details */}
            <Grid
                item
                style={{ marginLeft: 16, flex: 1 }}
            >
                <Typography
                    letterSpacing={0.5}
                    fontFamily={"primaryFont"}
                    fontWeight={"bold"}
                >
                    {item.product.name}
                </Typography>
                <Typography
                    letterSpacing={0.5}
                    fontFamily={"primaryFont"}
                    fontWeight={"bold"}
                    variant="caption"
                    color={"gray"}
                >
                    Code: {item.product.code.substring(0, 6).toUpperCase()}
                </Typography>
                <Typography letterSpacing={0.5} />
                {
                    item.product.variantType && (
                        <Typography
                            letterSpacing={0.5}
                            fontFamily={"primaryFont"}
                            fontWeight={"bold"}
                            variant="caption"
                            color={"gray"}
                        >
                            {`${item.product.variantType}: ${item.selectedVariant}`}
                        </Typography>
                    )
                }
                <Typography letterSpacing={0.5} />
            </Grid>

            {/* Product Quantity */}
            <Grid item>
                {
                    item.quantity > 1 && (
                        <Typography
                            letterSpacing={0.5}
                            fontFamily={"primaryFont"}
                            variant="caption"
                            fontWeight={"bold"}
                            color={"gray"}
                            textAlign={"right"}
                        >
                            {`R ${item.product.price}  \t ${item.quantity > 1 ? "x" : ""} ${item.quantity > 1 ? `${item.quantity}` : ""
                                }`}
                        </Typography>
                    )
                }
                <Typography letterSpacing={0.5} />
                <Typography
                    letterSpacing={1}
                    fontFamily={"primaryFont"}
                    fontWeight={"bold"}
                    variant="subtitle2"
                    textAlign={"right"}
                >
                    {`R ${(item.product.price * item.quantity).toFixed(2)}`}
                </Typography>
            </Grid>
        </Grid>
    );
};