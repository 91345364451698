import { Grid, Typography } from "@mui/material";
import { ScrollView } from "react-native";
import { useState, useRef } from "react";
import { PortalCategoryFilters } from "./Menu/PortalCategoryFilters";
import { PortalScreenComponent } from "../../models/enums/ScreenComponents";
import Products from "./Screens/Products/Products";
import { navigationStore } from "../../app/stores/Navigation";
import ProductWishlist from "./Screens/Wishlist/ProductWishlist";

export const Portal = () => {
    const { CategoriesFilterScroller, CategoriesFilterSideMenu } = PortalCategoryFilters();
    const { portalCurrentScreen, updatePortalCurrentScreen } = navigationStore(state => state);
    const scrollViewRef = useRef<ScrollView>(null);
    const paddingX = 4;

    const SelectedScreen = () => {
        switch (portalCurrentScreen) {
            case PortalScreenComponent.Products:
                return <Products />;
            // case PortalScreenComponent.Orders:
            //     return <Orders />;
            case PortalScreenComponent.Wishlist:
                return <ProductWishlist />;
            // case PortalScreenComponent.Users:
            //     return <Users />;
            default:
                return <Products />;
        }
    };

    return (
        <ScrollView
            style={{ backgroundColor: "white" }}
            contentContainerStyle={{ overflow: "visible" }}
            stickyHeaderIndices={[0]}
            stickyHeaderHiddenOnScroll={false}
        >
            <CategoriesFilterScroller
                scrollViewRef={scrollViewRef}
                selectedCategories={[portalCurrentScreen]}
                setSelectedCategories={(categories: PortalScreenComponent[]) => updatePortalCurrentScreen(categories[0])}
            />
            {/* <Grid container mt={4} mx={4}>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ fontFamily: "primaryFont", letterSpacing: 0.5, fontWeight: "bold" }}>
                        Admin Portal
                    </Typography>
                </Grid>
            </Grid> */}
            <Grid
                container
                flexDirection={{ xs: "row", md: "column" }}
                sx={{
                    paddingX: { xs: 2, md: paddingX },
                    alignContent: { xs: "center", md: "flex-start" },
                }}
                marginTop={4}
            >
                <CategoriesFilterSideMenu
                    scrollViewRef={scrollViewRef}
                    selectedCategories={[portalCurrentScreen]}
                    setSelectedCategories={(categories: PortalScreenComponent[]) => updatePortalCurrentScreen(categories[0])}
                />
                <Grid
                    item
                    minWidth={"85%"}
                    xs={12}
                    md={9}
                >
                    <SelectedScreen />
                </Grid>
            </Grid>
        </ScrollView>
    );
};