import { Image, StyleSheet, Text, View, StyleProp, ImageStyle } from "react-native";
import { appConfigStore } from "../app/stores/AppConfig";
import { AppHeaderName } from "../config/constants";

export const AppLogo = ({ style }: { style?: StyleProp<ImageStyle> }) => {
	const darkMode = appConfigStore((state) => state.darkMode);
	const heroImage = darkMode
		? require(`../assets/faded-logo.png`)
		: require(`../assets/faded-logo.png`);

	return (
		<View
			style={{
				alignItems: "center",
				marginTop: 12,
			}}
		>
			<Image
				style={[styles.heroImage, style as ImageStyle]}
				source={heroImage}
			/>
			<Text style={{ ...styles.heroTitle, ...{ color: "black", fontWeight: "bold" } }}>{AppHeaderName}</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	heroImage: {
		width: 50,
		aspectRatio: 1,
		resizeMode: "contain",
		borderRadius: "50%",
	},
	heroTitle: {
		marginTop: 18,
		fontSize: 18,
		fontWeight: "600",
	},
});
