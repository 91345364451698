import { TableRow, TableCell, IconButton, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { Wishlist } from "../../../../../models/DB";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { WishlistEditor } from "./WishlistEditor";
import { formatTimestamp } from "../../../../../utils/customFunctions";


interface WishlistRowProps {
    wishlist: Wishlist;
}

export const WishlistRow = ({ wishlist }: WishlistRowProps) => {
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const IconToShow = () => {
        return open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
    };

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    "& > *": { borderBottom: "unset" },
                }}
            >
                <TableCell component="th" scope="order">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <IconButton
                            size="small"
                            onClick={() => setOpen(!open)}
                            sx={{ flexDirection: 'column' }}
                        >
                            <IconToShow />
                        </IconButton> */}
                        <Typography variant="body2"
                            sx={{
                                marginLeft: 4,
                                fontSize: 11,
                                fontFamily: "primaryFont",
                                letterSpacing: 0.5,
                                textTransform: 'capitalize'
                            }}>
                            {wishlist.code}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell sx={{ fontSize: 11, fontFamily: "primaryFont", letterSpacing: 0.5 }}>
                    {wishlist.email}
                </TableCell>
                <TableCell sx={{ fontSize: 11, fontFamily: "primaryFont", letterSpacing: 0.5 }}>
                    {wishlist.firstName} {wishlist.lastName}
                </TableCell>
                <TableCell sx={{ fontSize: 11, fontFamily: "primaryFont", letterSpacing: 0.5 }}>
                    {wishlist.product.name}
                </TableCell>
                <TableCell sx={{ fontSize: 11, fontFamily: "primaryFont", letterSpacing: 0.5, display: { xs: "none", sm: "flex" } }}>
                    {formatTimestamp(wishlist.created)}
                </TableCell>
            </TableRow>
            {
                // <WishlistEditor
                //     wishlist={wishlist}
                //     open={open}
                //     isEditing={isEditing}
                //     setIsEditing={setIsEditing}
                // />
            }
        </React.Fragment>
    );
};