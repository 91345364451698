import { useCallback, useState } from "react";
import { User, Session } from "@supabase/supabase-js";
import { userStore } from "../../stores/User";
import { AccessLevel } from "../../../models/enums/AccessLevel";

const useAuthSession = () => {
    const { updateUser } = userStore((state) => state);
    const [isMonitoring, setIsMonitoring] = useState(false);

    let interval;

    const saveSession = useCallback((user: User, session: Session) => {
        updateUser({
            info: user,
            session: session,
            authenticated: true,
            role: user.user_metadata.role
        });

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("session", JSON.stringify(session));
    }, []);

    const loadSession = useCallback(() => {
        const user = localStorage.getItem("user");
        const session = localStorage.getItem("session");
        if (user && session) {
            return {
                user: JSON.parse(user) as User,
                session: JSON.parse(session) as Session,
            };
        }
        return null;
    }, []);

    const clearSession = useCallback(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("session");

        if(interval) {
            console.log("Clearing session monitoring");
            clearInterval(interval);
            setIsMonitoring(false);
        }

        updateUser({
            info: new Object() as User,
            session: new Object() as Session,
            authenticated: false,
            role: AccessLevel.User
        });
    }, [interval]);

    const checkSession = useCallback(() => {
        console.log("Checking session");
        const session = loadSession();
        if (session && session.session && session.session.expires_at) {
            const currentTime = Math.floor(Date.now() / 1000);

            if (session.session.expires_at > currentTime) {
                console.log("Session is still valid");
                updateUser({
                    info: session.user,
                    session: session.session,
                    authenticated: true,
                    role: session.user.user_metadata.role
                });
            } else {
                console.log("Session expired");
                clearSession();
                updateUser({
                    info: new Object() as User,
                    session: new Object() as Session,
                    authenticated: false,
                    role: AccessLevel.User
                });
            }
        } else {
            console.log("No session found");
            clearSession();
            updateUser({
                info: new Object() as User,
                session: new Object() as Session,
                authenticated: false,
                role: AccessLevel.User
            });
        }
    }, [loadSession, clearSession, updateUser]);

    return {
        saveSession,
        clearSession,
        checkSession,
    };
};

export default useAuthSession;
