import { productStore } from "../../app/stores/Product";
import axios, { HttpStatusCode } from "axios";
import { Product } from "../../models/DB";
import { ResponseModel } from "../../models/Response";
import { ApiEndpoints } from "../../config/constants";

export const ProductsService = () => {
	const updateProducts = productStore((state) => state.updateProducts);

	const getAllProducts = async () => {
		try {
			const response = await axios.get<ResponseModel<Product[]>>(ApiEndpoints.Products.GetAll);
			const { data, statusCode, errorMessage } = response.data;

			if (statusCode === HttpStatusCode.Ok && data) {
				if(data.length > 0) {
					updateProducts(data);
				}
				return;
			}
			
			console.log("Error fetching products:", errorMessage);
		} catch (error) {
			console.log("Error fetching products:", error);
		}
	};

	const getProductById = async (id: string) => {
		try {
			const response = await axios.get<ResponseModel<Product>>(ApiEndpoints.Products.GetById(id));
			const { data, statusCode, errorMessage } = response.data;

			if (statusCode === HttpStatusCode.Ok && data) {
				return data;
			}
			return console.error("Error fetching product by id:", errorMessage);
		} catch (error) {
			console.log("Error fetching product by id:", error);
		}
	};

	return { getAllProducts, getProductById };
};
