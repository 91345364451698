import React, { useEffect, useState } from "react";
import { Text, StyleSheet, Animated } from "react-native";
import { BasicButton } from "../../components/BasicButton";
import { Box, Container, Grid, IconButton } from "@mui/material";
import { BasicTextField } from "../../components/BasicTextField";
import AuthService from "../../services/supabase/AuthService";
import { primaryColor, stateErrorColor, stateSuccessColor } from "../../config/constants";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { useIsFocused } from "@react-navigation/native";
import Icon from "react-native-vector-icons/Ionicons";
export const Register = () => {
	const isFocused = useIsFocused();
	const { createStandardUser } = AuthService();
	const { navigateToScreen } = useScreenComponent();
	const [fadeAnimation] = useState(new Animated.Value(0));
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState<boolean | null>(null);

	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [passwordVisible, setPasswordVisible] = useState(false);

	useEffect(() => {
		if (isFocused) fadeIn();
	}, [isFocused]);

	const fadeIn = () => {
		return Animated.timing(fadeAnimation, {
			toValue: 1,
			duration: 750,
			useNativeDriver: false,
		}).start();
	};

	const fadeOut = (doAfter: () => void) => {
		return Animated.timing(fadeAnimation, {
			toValue: 0,
			duration: 750,
			useNativeDriver: false,
		}).start(() => {
			doAfter();
		});
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		const data = new FormData(event.currentTarget);
		const firstName = data.get("firstName") as string;
		const lastName = data.get("lastName") as string;
		const email = data.get("email") as string;
		const mobileNumber = data.get("mobileNumber") as string;
		const password = data.get("password") as string;

		const { success, message } = await createStandardUser(
			email,
			password,
			mobileNumber,
			firstName,
			lastName
		);
		setLoading(false);
		setSuccess(success);
		if (success) {
			setErrorMessage(message);
		} else {
			setErrorMessage(message);
		}
	};

	const handlePasswordVisibilityToggle = () => {
		setPasswordVisible(!passwordVisible);
	};

	return (
		<Animated.View style={{ opacity: fadeAnimation }}>
			<Container
				component={"main"}
				maxWidth={"sm"}
				sx={{ mt: 4 }}
			>
				<Box
					sx={{
						marginTop: { sm: 4 },
						display: "flex",
						flexDirection: "column",
						backgroundColor: "white",
						borderRadius: 2,
					}}
					boxShadow={"0px 8px 10px rgba(0, 0, 0, 0.1)"}
				>
					<Box
						component={"form"}
						onSubmit={handleSubmit}
						sx={{ m: 8 }}
					>
						<Grid
							container
							mb={2}
							flexDirection={"column"}
							spacing={1}
						>
							<Grid item>
								<Text style={styles.title}>Register</Text>
							</Grid>
							<Grid
								item
								sx={{ mb: 2 }}
							>
								<Text style={styles.subtitle}>Join the community</Text>
							</Grid>
						</Grid>
						<Grid
							container
							justifyContent={"center"}
							spacing={2}
						>
							<Grid
								item
								xs={12}
								sm={6}
							>
								<BasicTextField
									size={"small"}
									autoComplete={"given-name"}
									name={"firstName"}
									required
									fullWidth
									id={"firstName"}
									label={"First Name"}
									sx={{ input: { color: "black" } }}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
							>
								<BasicTextField
									size={"small"}
									required
									fullWidth
									id={"lastName"}
									label={"Last Name"}
									name={"lastName"}
									sx={{ input: { color: "black" } }}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<BasicTextField
									size={"small"}
									required
									fullWidth
									id={"email"}
									label={"Email Address"}
									name={"email"}
									sx={{ input: { color: "black" } }}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<BasicTextField
									size={"small"}
									required
									fullWidth
									name={"mobileNumber"}
									label={"Mobile Number"}
									type={"tel"}
									id={"mobileNumber"}
									sx={{ input: { color: "black" } }}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<BasicTextField
									size={"small"}
									required
									fullWidth
									name={"password"}
									label={"Password"}
									type={passwordVisible ? "text" : "password"}
									id={"password"}
									sx={{ input: { color: "black" } }}
									InputProps={{
										endAdornment: (
											<IconButton onClick={handlePasswordVisibilityToggle}>
												<Icon size={20} name={passwordVisible ? "eye-off" : "eye"} />
											</IconButton>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
								<Text
									style={[
										styles.errorMessage,
										{ color: success ? stateSuccessColor : stateErrorColor }
									]}
								>
									{errorMessage}
								</Text>
							</Grid>
							{/* <Grid
								container
								marginY={1}
								direction={"row"}
								justifyContent={"center"}
								alignItems={"center"}
							>
								<Grid item>
									<FormControlLabel
										checked={accessLevel === AccessLevel.Admin}
										control={
											<Radio
												size={"small"}
												sx={{ color: "black" }}
												onChange={handleChange}
											/>
										}
										sx={{ color: "black" }}
										value={AccessLevel.Admin}
										label={AccessLevel.Admin}
									/>
								</Grid>
								<Grid item>
									<FormControlLabel
										checked={accessLevel === AccessLevel.Manager}
										control={
											<Radio
												size={"small"}
												sx={{ color: "black" }}
												onChange={handleChange}
											/>
										}
										sx={{ color: "black" }}
										value={AccessLevel.Manager}
										label={AccessLevel.Manager}
									/>
								</Grid>
								<Grid item>
									<FormControlLabel
										checked={accessLevel === AccessLevel.Auditor}
										control={
											<Radio
												size={"small"}
												sx={{ color: "black" }}
												onChange={handleChange}
											/>
										}
										sx={{ color: "black" }}
										value={AccessLevel.Auditor}
										label={AccessLevel.Auditor}
									/>
								</Grid>
								<Grid item>
									<FormControlLabel
										checked={accessLevel === AccessLevel.Sales}
										control={
											<Radio
												size={"small"}
												sx={{ color: "black" }}
												onChange={handleChange}
											/>
										}
										sx={{ color: "black" }}
										value={AccessLevel.Sales}
										label={AccessLevel.Sales}
									/>
								</Grid>
							</Grid> */}
						</Grid>
						<Grid
							container
							direction={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							spacing={2}
						>
							<Grid item>
								<BasicButton
									title={"Register"}
									type={"submit"}
									style={{ marginTop: 32, marginBottom: 6, width: 160, backgroundColor: primaryColor }}
									isLoading={loading}
								/>
							</Grid>
							<Grid item>
								<BasicButton
									title={"Already have an account?"}
									variant={"text"}
									style={{ color: primaryColor }}
									onClick={() => {
										fadeOut(() => {
											// updateDefaultRoute(DefaultRoute.Login);
											navigateToScreen(ScreenComponent.Login);
										});
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		</Animated.View >
	);
};

const styles = StyleSheet.create({
	title: {
		fontSize: 22,
		fontWeight: "600",
		fontFamily: "primaryFont",
		marginBottom: 4,
		textAlign: "center",
		color: "black",
	},
	subtitle: {
		fontSize: 16,
		fontWeight: "600",
		fontFamily: "primaryFont",
		justifyContent: "center",
		textAlign: "center",
		color: "black",
	},
	errorMessage: {
		color: stateErrorColor,
		textAlign: "center",
		marginTop: 10,
		fontFamily: "primaryFont",
	},
});
