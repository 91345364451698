import { useEffect, useRef, useMemo, memo } from "react";
import { ScrollView, TouchableOpacity, Text } from "react-native";
import { Chip, Grid } from "@mui/material";
import { primaryColor } from "../../../../config/constants";
import { Categories } from "../../../../app/stores/Product";


interface CategoriesFilterScrollerProps {
    scrollViewRef: React.RefObject<ScrollView>;
    allCategories: Categories[];
    selectedCategories: Categories[];
    setSelectedCategories: (categories: Categories[]) => void;
    getProductsLength: (category: string) => number;
}

export const CategoriesFilterScroller = memo(({ scrollViewRef, allCategories, selectedCategories, setSelectedCategories, getProductsLength }: CategoriesFilterScrollerProps) => {
    const scrollViewScrollerRef = useRef(scrollViewRef);
    const scrollPosScrollerRef = useRef(0);
    const getIsSelected = (cat: string) => selectedCategories.includes(cat as unknown as Categories);

    useEffect(() => {
        // Restore scroll position
        if (scrollViewScrollerRef.current) {
            (scrollViewScrollerRef.current).scrollTo({ x: scrollPosScrollerRef.current, animated: false });
        }
    }, [scrollViewScrollerRef.current]);

    const categoryChips = useMemo(() => allCategories.map((cat, index) => {
        const isSelectedCategory = getIsSelected(cat.category);
        const label = isSelectedCategory ? `${cat.category} (${getProductsLength(cat.category)} Items)`
            : cat.category;

        return (
            <Chip
                component="div"
                key={`${cat}-${index}`}
                label={label}
                variant={"filled"}
                size="medium"
                sx={{
                    px: 0.5,
                }}
                style={{
                    letterSpacing: 0.5,
                    fontFamily: "primaryFont",
                    fontWeight: "600",
                    minHeight: 40,
                    marginRight: 8,
                    borderRadius: 20,
                    backgroundColor: isSelectedCategory ? primaryColor : "#ececec",
                    color: isSelectedCategory ? "white" : "black"
                }}
                onClick={() =>
                    setSelectedCategories([cat.category as unknown as Categories])
                }
            />
        );
    }), [allCategories, selectedCategories, getProductsLength]);

    return (
        <Grid
            item
            xs={12}
            display={{ md: "none" }}
            sx={{
                backgroundColor: "white",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
            }}
        >
            <ScrollView
                horizontal
                contentContainerStyle={{
                    paddingVertical: 22,
                    paddingLeft: 15,
                }}
                ref={scrollViewScrollerRef}
                onScroll={(event) => {
                    scrollPosScrollerRef.current = event.nativeEvent.contentOffset.x;
                }}
                scrollEventThrottle={16}
            >
                {categoryChips}
            </ScrollView>
        </Grid>
    );
});


interface CategoriesFilterSideMenuProps {
    scrollViewRef: React.RefObject<ScrollView>;
    allCategories: Categories[];
    selectedCategories: Categories[];
    setSelectedCategories: (categories: Categories[]) => void;
    getProductsLength: (category: string) => number;
}

export const CategoriesFilterSideMenu = memo(({ scrollViewRef, allCategories, selectedCategories, setSelectedCategories, getProductsLength }: CategoriesFilterSideMenuProps) => {
    const scrollViewSideRef = useRef(scrollViewRef);
    const scrollPosSideRef = useRef(0);
    const getIsSelected = (cat: string) => selectedCategories.includes(cat as unknown as Categories);

    useEffect(() => {
        // Restore scroll position
        if (scrollViewSideRef.current) {
            const scrollView = scrollViewSideRef.current as unknown as ScrollView;
            scrollView.scrollTo({ y: scrollPosSideRef.current, animated: false });
        }
    }, [scrollViewSideRef.current]);

    const categoryItems = useMemo(() => allCategories.map((cat, index) => {
        const isSelectedCategory = getIsSelected(cat.category);
        const label = isSelectedCategory
            ? `${cat.category} (${getProductsLength(cat.category)} Items)`
            : cat.category;

        return (
            <TouchableOpacity
                key={`${cat}-${index}`}
                onPress={() => setSelectedCategories([cat.category as unknown as Categories])}
            >
                <Grid
                    item
                    sx={{
                        mb: 1,
                        padding: 1,
                        borderRadius: 4,
                        backgroundColor: isSelectedCategory ? primaryColor : "transparent",
                        transition: "background-color 0.4s",
                        ":hover": {
                            backgroundColor: "#e0e0e0",
                        },
                    }}
                >
                    <Text style={{
                        letterSpacing: 0.5,
                        fontFamily: "primaryFont",
                        fontWeight: "600",
                        marginLeft: 8,
                        color: isSelectedCategory ? "white" : "black"
                    }}>
                        {label}
                    </Text>
                </Grid>
            </TouchableOpacity>
        );
    }), [allCategories, selectedCategories, getProductsLength]);

    return (
        <Grid
            item
            flexDirection={"row"}
            display={{ xs: "none", md: "flex" }}
            md={3}
            minWidth={"20%"}
            marginRight={2}
        >
            <ScrollView
                style={{
                    flex: 1,
                    paddingRight: 10
                }}
                ref={scrollViewSideRef}
                onScroll={(event) => {
                    scrollPosSideRef.current = event.nativeEvent.contentOffset.y;
                }}
                scrollEventThrottle={16}
            >
                {categoryItems}
            </ScrollView>
        </Grid>
    );
});