// Base URL
// export const baseUrl = 'http://localhost:3000';
export const baseUrl = 'https://europe-west1-toefadeit.cloudfunctions.net/api';


// App prefix
export const prefix = "stayfaded://";

// App header name
export const AppHeaderName = "Stay Faded";
export const AppLogo = "faded-logo.png";

// Colors
export const primaryColor = "purple";
export const stateErrorColor = "#C41E3A";
export const stateSuccessColor = "#228B22";
export const stateWarningColor = "orange";
export const stateInfoColor = "#0096FF";
export const stateDisabledColor = "#D3D3D3";

// Root paths
export const RootPaths = {
    Splash: "home",
    Shop: "shop",
    Product: "product",
    How_It_Works: "tour",
    Bookings: "bookings",
    Orders: "orders",
    Contact_Us: "contactus",
    Cart: "cart",
    Register: "register",
    Login: "login",
    Portal: "portal",
    NotFound: "*",
}

// API Endpoints
export const ApiEndpoints = {
    Auth: {
        SignIn: `${baseUrl}/auth/signin`,
        SignUp: `${baseUrl}/auth/signup`,
        SignOut: `${baseUrl}/auth/signout`,
    },
    Orders: {
        GetById: (id: string) => `${baseUrl}/orders/${id}`,
        Create: `${baseUrl}/orders`,
    },
    Products: {
        GetAll: `${baseUrl}/products`,
        GetById: (id: string) => `${baseUrl}/products/${id}`,
        
    },
    Wishlist: {
        AddToWishlist: `${baseUrl}/wishlist`,
        GetWishlist: (email: string) => `${baseUrl}/wishlist/${email}`,
        GetAllWishlist: `${baseUrl}/wishlist`,
        RemoveFromWishlist: (id: string) => `${baseUrl}/wishlist/${id}`,
    },
    Admin: {
        CreateProduct: `${baseUrl}/admin/products`,
        UpdateProduct: `${baseUrl}/admin/products`,
        GetAllOrders: `${baseUrl}/admin/orders`,
        DeleteProduct: (id: string, code: string) => `${baseUrl}/admin/products/${id}/${code}`,
        UpdateOrder: `${baseUrl}/admin/orders`,
        SignIn: `${baseUrl}/admin/signin`,
        SignUp: `${baseUrl}/admin/signup`,
    },
};

// Menu Items
export const MenuItems = {
    UserAccess: "User Access",
    Products: "Products",
    Orders: "Orders",
};

export const VAT_PERCENTAGE = 15;