import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button, Typography } from "@mui/material";
import { AddCircle, AddCircleOutline, Upload } from '@mui/icons-material';
import { Product, ProductImage } from "../../../../../models/DB";
import { Image } from "react-native";
import { BasicButton } from "../../../../../components/BasicButton";
import { BasicTextField } from "../../../../../components/BasicTextField";
import { productStore } from "../../../../../app/stores/Product";
import { primaryColor } from "../../../../../config/constants";
import PortalService from "../../../../../services/supabase/PortalService";

interface AddProductFormProps {
    products: Product[];
    onClose: () => void;
}

export const AddProductForm: React.FC<AddProductFormProps> = ({ products, onClose }) => {

    const { createProduct } = PortalService();
    const { updateProducts } = productStore(state => state);

    const [productData, setProductData] = useState<Partial<Product>>({
        name: "",
        category: "",
        price: 0,
        stockOnHand: 0,
        discount: 0,
        inStock: true,
        variantType: "",
        variantOptions: [],
        images: []
    });

    const [imagePreviews, setImagePreviews] = useState<{ [key: string]: string }>({});
    const [imageFiles, setImageFiles] = useState<{ [key: string]: File | null }>({});
    const [loading, setLoading] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setProductData({ ...productData, [name]: value });
    };

    const handleImageUpload = (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const renamedFile = new File([file], `${type}`, { type: file.type });
            setImageFiles({ ...imageFiles, [type]: renamedFile });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviews({ ...imagePreviews, [type]: reader.result as string });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        const images: ProductImage[] = Object.keys(imageFiles).map((type) => ({
            type,
            url: "" // The URL will be set by the server after upload
        }));

        setLoading(true);
        const validImageFiles = Object.values(imageFiles).filter((file): file is File => file !== null);
        const product = await createProduct({ ...productData, images } as Product, validImageFiles);
        setLoading(false);
        if (product) {
            onClose();
            setTimeout(() => {
                updateProducts([...products, product]);
            }, 500);
        } else {
            console.error("Error creating product");
        }
    };

    return (
        <Dialog
            open
            fullWidth
            onClose={onClose}
            maxWidth="lg"
            sx={{
                boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.1)",
                borderRadius: 12,
            }}
        >
            <DialogTitle
                sx={{
                    marginX: 2,
                    marginY: 4,
                }}
            >
                Add New Product
            </DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off">
                    <Grid container spacing={3} sx={{ padding: 2 }}>
                        <Grid item xs={12} md={4} mb={2}>
                            <Grid container flexDirection="column" spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            fontFamily: "primaryFont",
                                            letterSpacing: 0.5,
                                            textAlign: "center",
                                        }}
                                    >
                                        Product Images
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        mt={1}
                                        sx={{
                                            fontFamily: "primaryFont",
                                            letterSpacing: 0.5,
                                            textAlign: "center",
                                        }}
                                    >
                                        Select an image to add or replace
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        mb={3}
                                        sx={{
                                            fontFamily: "primaryFont",
                                            letterSpacing: 0.5,
                                            textAlign: "center",
                                        }}
                                    >
                                        You can upload up to 3 images
                                    </Typography>
                                </Grid>
                                <Grid container spacing={2} justifyContent="center">
                                    {/* Product Thumbnail */}
                                    {["thumbnail", "image1", "image2"].map((type) => (
                                        <Grid item xs={4} key={type}>
                                            <Button
                                                fullWidth
                                                variant="text"
                                                component="label"
                                                sx={{
                                                    aspectRatio: "1 / 1",
                                                    padding: 0,
                                                    borderRadius: 6,
                                                    overflow: "hidden",
                                                    borderStyle: "solid",
                                                    borderWidth: 1.75,
                                                    borderColor: primaryColor,
                                                }}
                                            >
                                                <Image
                                                    source={
                                                        imagePreviews[type]
                                                            ? { uri: imagePreviews[type] }
                                                            : require("../../../../../assets/no-image.png")
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        aspectRatio: "1 / 1",
                                                        resizeMode: "cover",
                                                    }}
                                                />
                                                <input
                                                    hidden
                                                    type="file"
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    onChange={handleImageUpload(type)}
                                                />
                                            </Button>
                                            <Typography
                                                variant="body2"
                                                mt={1.5}
                                                sx={{
                                                    fontFamily: "primaryFont",
                                                    letterSpacing: 0.5,
                                                    textAlign: "center",
                                                }}
                                            >
                                                {type.charAt(0).toUpperCase() + type.slice(1).replace(/([a-zA-Z])(\d)/g, "$1 $2")}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <BasicTextField
                                        name="name"
                                        label="Name"
                                        size="small"
                                        fullWidth
                                        value={productData.name}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <BasicTextField
                                        name="category"
                                        label="Category"
                                        fullWidth
                                        size="small"
                                        value={productData.category}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <BasicTextField
                                        name="price"
                                        label="Price"
                                        type="number"
                                        fullWidth
                                        size="small"
                                        value={productData.price}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <BasicTextField
                                        name="stockOnHand"
                                        label="Stock On Hand"
                                        type="number"
                                        fullWidth
                                        size="small"
                                        value={productData.stockOnHand}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item>
                                    <BasicTextField
                                        name="discount"
                                        label="Discount"
                                        fullWidth
                                        size="small"
                                        value={productData.discount}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <BasicButton
                                        title="Add"
                                        variant="contained"
                                        sx={{
                                            marginBottom: 2,
                                            maxHeight: 32,
                                        }}
                                        startIcon={<AddCircleOutline />}
                                    />
                                    <BasicTextField
                                        name="variantType"
                                        label="Variant Type"
                                        fullWidth
                                        size="small"
                                        value={productData.variantType}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item mt={2}>
                                    <BasicButton
                                        title="Add"
                                        variant="contained"
                                        sx={{
                                            marginBottom: 2,
                                            maxHeight: 32,
                                        }}
                                        startIcon={<AddCircleOutline />}
                                    />
                                    <BasicTextField
                                        name="variantOptions"
                                        label="Variant Options (comma separated)"
                                        fullWidth
                                        size="small"
                                        value={productData.variantOptions?.join(", ") || ""}
                                        onChange={(e) => setProductData({ ...productData, variantOptions: e.target.value.split(", ") })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: 4 }}>
                <BasicButton
                    title="Cancel"
                    variant="outlined"
                    onClick={onClose}
                />
                <BasicButton
                    title={`Add Product`}
                    variant="contained"
                    onClick={handleSubmit}
                    isLoading={loading}
                    startIcon={<AddCircle />}
                />
            </DialogActions>
        </Dialog>
    );
};