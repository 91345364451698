import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { Fragment, useState } from "react";
import { ContactDetails } from "../../../models/DB";
import { BasicButton } from "../../../components/BasicButton";
import { Box } from "@mui/material";

interface ContactFormProps {
	onBack: () => void;
	onNext: (details: ContactDetails) => void;
}

export const ContactForm = ({ onBack, onNext }: ContactFormProps) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [contactNo, setContactNo] = useState("");

	const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFirstName(event.target.value);
	};

	const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLastName(event.target.value);
	};

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleContactNoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setContactNo(event.target.value);
	};

	const handleNext = () => {
		const details: ContactDetails = {
			firstName,
			lastName,
			email,
			contactNo,
		};
		onNext(details);
	};

	return (
		<Box component="form" onSubmit={handleNext}>
			<Typography
				variant="h6"
				gutterBottom
				mt={2}
				mb={4}
			>
				Contact Details
			</Typography>
			<Grid
				container
				spacing={3}
			>
				<Grid
					item
					xs={12}
					md={6}
				>
					<TextField
						required
						fullWidth
						id="firstName"
						label="First Name"
						variant="outlined"
						autoComplete="given-name"
						onChange={handleFirstNameChange}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
				>
					<TextField
						required
						fullWidth
						id="lastName"
						label="Last Name"
						variant="outlined"
						autoComplete="family-name"
						onChange={handleLastNameChange}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
				>
					<TextField
						required
						fullWidth
						id="email"
						label="Email Address"
						variant="outlined"
						autoComplete="email"
						onChange={handleEmailChange}
					/>
				</Grid>
				<Grid
					item
					xs={12}
						md={6}
				>
					<TextField
						required
						fullWidth
						id="contactNo"
						label="Contact Number"
						variant="outlined"
						autoComplete="tel"
						onChange={handleContactNoChange}
					/>
				</Grid>
			</Grid>
			{/* Buttons to move to the next step or go back */}
			<Grid
				container
				justifyContent="space-between"
				mt={4}
			>
				<BasicButton
					title={"Back"}
					variant="outlined"
					onClick={onBack}
				/>
				<BasicButton
					title={"Next"}
					variant="contained"
					type="submit"
				/>
			</Grid>
		</Box>
	);
};
