import axios, { HttpStatusCode } from "axios";
import { Session, User } from "@supabase/supabase-js";
import { ResponseModel } from "../../models/Response";
import { ApiEndpoints } from "../../config/constants";
import useAuthSession from "../../app/hooks/custom/useAuthSession";

const AuthService = () => {
    const { saveSession, clearSession } = useAuthSession();
    
    const signInWithEmailAndPassword = async (email: string, password: string) => {
        try {
            const { data: { data, statusCode, errorMessage } } = await axios.post<ResponseModel<{ user: User, session: Session }>>(
                ApiEndpoints.Auth.SignIn, {
                    email: email,
                    password: password,
                    withCredentials: true,
                }
            );

            console.log("🚀 ~ signInWithEmailAndPassword ~ data:", JSON.stringify(data, null, 2))

            if (data?.user && statusCode === HttpStatusCode.Ok) {
                setTimeout(() => {
                    saveSession(data.user, data.session);
                }, 1000);

                return {
                    success: true,
                    message: "Signed in successfully",
                };
            }

            return {
                success: false,
                message: errorMessage ?? "We're having trouble signing you in. Please try again later.",
            };
        } catch (error) {
            console.error("Error: ", error);
            return {
                success: false,
                message: "Error signing in: " + error,
            };
        }
    };

    const createStandardUser = async (email: string, password: string, phone: string, firstName: string, lastName: string) => {
        try {
            const { data: { statusCode, errorMessage } } = await axios.post<ResponseModel<{ user: User, session: Session }>>(
                ApiEndpoints.Auth.SignUp, {
                email: email,
                password: password,
                phone: phone,
                firstName: firstName,
                lastName: lastName,
            });
            
            if (statusCode === HttpStatusCode.Created) {
                return {
                    success: true,
                    message: `Done! Please check ${email} for your verification link.`,
                };
            }

            return {
                success: false,
                message: errorMessage ?? "We're having trouble signing you up. Please try again later.",
            };
        } catch (error) {
            console.error("Error: ", error);
            return {
                success: false,
                message: "Error signing up: " + error,
            };
        }
    };

    const signOut = async () => {
        try {
            const { status } = await axios.post(ApiEndpoints.Auth.SignOut);
            
            if (status === HttpStatusCode.Created) {
                setTimeout(() => {
                    clearSession();
                }, 1000);
                return true;
            }
        } catch (error) {
            console.error("Error: ", error);
            return false;
        }

        return false;
    };
    
    return {
        signOut,
        signInWithEmailAndPassword,
        createStandardUser
    };
};

export default AuthService;