import { TableRow, TableCell, IconButton, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Product } from "../../../../../models/DB";
import { KeyboardArrowDown, KeyboardArrowUp, RemoveCircle } from "@mui/icons-material";
import { Image } from "react-native";
import { formatTimestamp, formatUpdatedTimestamp } from "../../../../../utils/customFunctions";
import { ProductEditor } from "./ProductEditor";
import { stateDisabledColor, stateErrorColor } from "../../../../../config/constants";

interface ProductRowProps {
    product: Product;
}

export const ProductRow = ({ product }: ProductRowProps) => {
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    useEffect(() => {
        if (isDeleted) {
            setOpen(false);
        }
    }, [isDeleted]);

    const IconToShow = () => {
        if (isDeleted) {
            return <RemoveCircle sx={{ color: stateErrorColor }} />;
        }
        return open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
    };

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    "& > *": { borderBottom: "unset" },
                    opacity: isDeleted ? 0.5 : 1,
                    backgroundColor:
                        isDeleted ? stateDisabledColor : "white"
                }}
            >
                <TableCell component="th" scope="product">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            size="small"
                            disabled={isDeleted}
                            onClick={() => setOpen(!open)}
                            sx={{ flexDirection: 'column' }}
                        >
                            <IconToShow />
                        </IconButton>
                        <Image
                            source={
                                product.images["thumbnail"]?.url
                                    ? { uri: product.images["thumbnail"].url }
                                    : require("../../../../../assets/no-image.png")
                            }

                            style={{
                                minHeight: 30,
                                maxHeight: 30,
                                aspectRatio: 1 / 1,
                                borderRadius: 4,
                                resizeMode: 'cover',
                                marginLeft: 8 // Add some space between the icon and the image
                            }}
                        />
                        <Typography variant="body2"
                            sx={{
                                marginLeft: 4,
                                fontSize: 12,
                                fontFamily: "primaryFont",
                                letterSpacing: 0.5,
                                textTransform: 'capitalize'
                            }}>
                            {product.name}
                        </Typography>
                    </Box>
                </TableCell>
                {/* <TableCell align="right" sx={{ fontSize: 12, fontFamily: "primaryFont", letterSpacing: 0.5 }}>R {product.price}</TableCell> */}
                {/* <TableCell align="right" sx={{ fontSize: 12, fontFamily: "primaryFont", letterSpacing: 0.5 }}>{product.stockOnHand}</TableCell>  */}
                <TableCell align="right" sx={{ fontSize: 12, fontFamily: "primaryFont", letterSpacing: 0.5 }}>{product.category}</TableCell>
                {/* <TableCell align="right" sx={{ fontSize: 12, fontFamily: "primaryFont", letterSpacing: 0.5 }}>{formatTimestamp(product.created)}</TableCell>
                <TableCell align="right" sx={{ fontSize: 12, fontFamily: "primaryFont", letterSpacing: 0.5 }}>{formatUpdatedTimestamp(product.updated)}</TableCell> */}
            </TableRow>
            {
                <ProductEditor
                    product={product}
                    open={open}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    setIsDeleted={setIsDeleted}
                />
            }
        </React.Fragment>
    );
};