import { useEffect, useRef, useMemo, memo } from "react";
import { ScrollView, TouchableOpacity, Text } from "react-native";
import { Chip, Grid } from "@mui/material";
import { primaryColor } from "../../../config/constants";
import { navigationStore } from "../../../app/stores/Navigation";
import { PortalScreenComponent } from "../../../models/enums/ScreenComponents";
import { userStore } from "../../../app/stores/User";
import { AccessLevel } from "../../../models/enums/AccessLevel";

interface CategoriesFilterScrollerProps {
    scrollViewRef: React.RefObject<ScrollView>;
    selectedCategories: PortalScreenComponent[];
    setSelectedCategories: (categories: PortalScreenComponent[]) => void;
}

interface CategoriesFilterSideMenuProps {
    scrollViewRef: React.RefObject<ScrollView>;
    selectedCategories: PortalScreenComponent[];
    setSelectedCategories: (categories: PortalScreenComponent[]) => void;
}

export const PortalCategoryFilters = () => {

    const { portalItemList } = navigationStore((state) => state);
    const { user: { role } } = userStore(state => state);
    const isAdmin = role?.toLowerCase() === AccessLevel.Admin.toLowerCase();

    const CategoriesFilterScroller = memo(({
        scrollViewRef,
        selectedCategories,
        setSelectedCategories
    }: CategoriesFilterScrollerProps) => {
        const scrollViewScrollerRef = useRef(scrollViewRef);
        const scrollPosScrollerRef = useRef(0);
        const getIsSelected = (cat: PortalScreenComponent) => selectedCategories.includes(cat);

        useEffect(() => {
            // Restore scroll position
            if (scrollViewScrollerRef.current) {
                (scrollViewScrollerRef.current).scrollTo({ x: scrollPosScrollerRef.current, animated: false });
            }
        }, [scrollViewScrollerRef.current]);

        const categoryChips = useMemo(() => portalItemList.map((cat, index) => {
            const isSelectedCategory = getIsSelected(cat);
            const label = cat;

            if (cat === PortalScreenComponent.Products && !isAdmin) return <></>;

            return (
                <Chip
                    component="div"
                    key={`${cat}-${index}`}
                    label={label}
                    variant={"filled"}
                    size="medium"
                    sx={{
                        px: 0.5,
                    }}
                    style={{
                        letterSpacing: 0.5,
                        fontFamily: "primaryFont",
                        fontWeight: "600",
                        minHeight: 40,
                        marginRight: 8,
                        borderRadius: 20,
                        backgroundColor: isSelectedCategory ? primaryColor : "#ececec",
                        color: isSelectedCategory ? "white" : "black"
                    }}
                    onClick={() =>
                        setSelectedCategories([cat])
                    }
                />
            );
        }), [portalItemList, selectedCategories, isAdmin]);

        return (
            <Grid
                item
                xs={12}
                display={{ md: "none" }}
                sx={{
                    backgroundColor: "white",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
                }}
            >
                <ScrollView
                    horizontal
                    contentContainerStyle={{
                        paddingVertical: 22,
                        paddingLeft: 15,
                    }}
                    ref={scrollViewScrollerRef}
                    onScroll={(event) => {
                        scrollPosScrollerRef.current = event.nativeEvent.contentOffset.x;
                    }}
                    scrollEventThrottle={16}
                >
                    {categoryChips}
                </ScrollView>
            </Grid>
        );
    });

    const CategoriesFilterSideMenu = memo(({
        scrollViewRef,
        selectedCategories,
        setSelectedCategories
    }: CategoriesFilterSideMenuProps) => {
        const scrollViewSideRef = useRef(scrollViewRef);
        const scrollPosSideRef = useRef(0);
        const getIsSelected = (cat: PortalScreenComponent) => selectedCategories.includes(cat);

        useEffect(() => {
            // Restore scroll position
            if (scrollViewSideRef.current) {
                const scrollView = scrollViewSideRef.current as unknown as ScrollView;
                scrollView.scrollTo({ y: scrollPosSideRef.current, animated: false });
            }
        }, [scrollViewSideRef.current]);

        const categoryItems = useMemo(() => portalItemList.map((cat, index) => {
            const isSelectedCategory = getIsSelected(cat);
            const label = cat;

            if (cat === PortalScreenComponent.Products && !isAdmin) return <></>;

            return (
                <TouchableOpacity
                    key={`${cat}-${index}`}
                    onPress={() => setSelectedCategories([cat])}
                >
                    <Grid
                        item
                        sx={{
                            mb: 1,
                            padding: 1,
                            borderRadius: 4,
                            backgroundColor: isSelectedCategory ? primaryColor : "transparent",
                            transition: "background-color 0.4s",
                            ":hover": {
                                backgroundColor: "#e0e0e0",
                            },
                        }}
                    >
                        <Text style={{
                            letterSpacing: 0.5,
                            fontFamily: "primaryFont",
                            fontWeight: "600",
                            marginLeft: 8,
                            color: isSelectedCategory ? "white" : "black"
                        }}>
                            {label}
                        </Text>
                    </Grid>
                </TouchableOpacity>
            );
        }), [portalItemList, selectedCategories, isAdmin]);

        return (
            <Grid
                item
                flexDirection={"row"}
                display={{ xs: "none", md: "flex" }}
                marginTop={14}
                md={3}
                minWidth={"15%"}
            >
                <ScrollView
                    style={{
                        maxHeight: portalItemList.length > 8 ? "80%" : "100%",
                        paddingRight: 10
                    }}
                    ref={scrollViewSideRef}
                    onScroll={(event) => {
                        scrollPosSideRef.current = event.nativeEvent.contentOffset.y;
                    }}
                    scrollEventThrottle={16}
                >
                    {categoryItems}
                </ScrollView>
            </Grid>
        );
    });

    return {
        CategoriesFilterScroller,
        CategoriesFilterSideMenu
    }
}



