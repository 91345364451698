import { Container, Grid } from "@mui/material";
import { useState } from "react";
import { Image, ScrollView, StyleSheet, Text } from "react-native";
import { appConfigStore } from "../../app/stores/AppConfig";
import { cartStore } from "../../app/stores/Cart";
import { BasicButton } from "../../components/BasicButton";
import { Checkout } from "./Checkout/Index";
import { navigationStore } from "../../app/stores/Navigation";
import { userStore } from "../../app/stores/User";
import { DefaultRoute } from "../../models/enums/DefaultRoute";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { OrderItemCard } from "../../components/OrderItemCard";

export const Cart = () => {
	const { darkMode } = appConfigStore((state) => state);
	const { cartItems, cartTotal, clearCart } = cartStore((state) => state);
	const [isConfirmingOrder, setIsConfirmingOrder] = useState(false);
	const { updateShowNavigationBar, updateDefaultRoute } = navigationStore((state) => state);
	const { user: { authenticated } } = userStore((state) => state);
	const { navigateToScreen } = useScreenComponent();

	// Apply Discounts (Replace the fixed discount amount with your logic)
	const discountAmount = 0;

	// Calculate Delivery Fee (Replace with your delivery fee logic)
	const deliveryFee = 100;

	// Determine Order Total
	const orderTotal = cartTotal - discountAmount + deliveryFee;

	const handleCheckoutStart = () => {
		if (authenticated) {
			updateShowNavigationBar(false);
			setIsConfirmingOrder(true);
		} else {
			updateDefaultRoute(DefaultRoute.Login);
			navigateToScreen(ScreenComponent.Login);
		}
	};

	const handleCheckoutComplete = () => {
		setIsConfirmingOrder(false);
		updateShowNavigationBar(true);
		clearCart();
	};

	const handleCheckoutReset = () => {
		setIsConfirmingOrder(false);
		updateShowNavigationBar(true);
	};

	const Cart = () => {
		return (
			<>
				{ isConfirmingOrder ? (
					<Checkout
						cartItems={ cartItems }
						onComplete={handleCheckoutComplete}
						onReset={handleCheckoutReset}
					/>
				) : (
					<>
						<Container
							component={ "main" }
							maxWidth={ "xl" }
						>
							<Grid
								container
								flexDirection={ "row" }
								marginTop={ { xs: 4, md: 8 } }
								gap={ 1 }
								justifyContent={ "center" }
							>
								<Grid
									container
									item
									lg={ 7 }
									flexDirection={ "column" }
									marginRight={ { md: 4 } }
								>
									<Grid
										item
										marginY={ { xs: 2, md: 2 } }
									>
										<Text style={ styles.heading }>Items in your Cart</Text>
									</Grid>
										{
											cartItems.map((item, index) => {
												return (
													<OrderItemCard
														key={`cartItemCard_${index}`}
														item={item}
													/>
												)
											})
										}
								</Grid>
								<Grid
									container
									item
									lg={ 4 }
									mb={ { xs: 14 } }
									mt={ { xs: 6 } }
									flexDirection={ "column" }
								>
									<Grid
										item
										marginY={ { xs: 2, md: 4 } }
									>
										<Text style={ styles.heading }>Summary</Text>
									</Grid>
									<Grid
										container
											direction="row"
											alignItems={"center"}
											marginBottom={2}
											border={2}

											padding={2}
											borderRadius={4}
											borderColor={"#f1f1f1"}
											boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.1)"}
											sx={{ borderColor: "transparent", gap: 10 }}
									>
										<Grid flex={ 0.6 }>
											<Grid>
												<Text style={ styles.blackSubDetailText }>Cart total</Text>
												</Grid>
										</Grid>
										<Grid flex={ 0.4 }>
											<Grid alignSelf={ "flex-end" }>
													<Text style={styles.summaryTotals}>R {cartTotal.toFixed(2)}</Text>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										container
										justifyContent={ "center" }
										mt={ 2 }
										padding={ 2 }
									>
										<BasicButton
												sx={{
													flex: 0.5,
													height: 45,
													minHeight: 45
												}}
												title={authenticated ? "Confirm Order" : "Login to Confirm Order"}
												onClick={handleCheckoutStart}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Container>
					</>
				) }
			</>
		);
	};

	return (
		<ScrollView contentContainerStyle={ styles.background }>
			{ cartItems.length > 0 ? (
				<Cart />
			) : (
				<Grid
					container
					flex={ 1 }
					flexDirection={ "column" }
					justifyContent={ "center" }
					alignContent={ "center" }
					alignItems={ "center" }
				>
					<Image
						style={ {
								resizeMode: "cover",
							margin: 12,
							width: 40,
							height: 40,
							tintColor: darkMode ? "white" : "grey",
						} }
						source={ require("../../assets/cart-icon.png") }
					/>
					<Text style={ styles.blackSubDetailText }>Your cart is empty</Text>
				</Grid>
			) }
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	background: {
		flex: 1,
	},
	image: {
		marginTop: 20,
		width: 50,
		height: 50,
		resizeMode: "contain",
		alignSelf: "center",
	},
	heading: {
		fontSize: 28,
		fontWeight: "800",
		fontFamily: "primaryFont",
		letterSpacing: 1,
		color: "black",
	},
	blackDetailText: {
		fontSize: 18,
		fontWeight: "900",
		fontFamily: "primaryFont",
		letterSpacing: 0.5,
		color: "black",
	},
	blackSubDetailText: {
		fontSize: 18,
		fontWeight: "900",
		fontFamily: "primaryFont",
		letterSpacing: 0.5,
		color: "black",
	},
	summaryTotals: {
		fontSize: 18,
		fontWeight: "600",
		fontFamily: "primaryFont",
		letterSpacing: 0.5,
		color: "gray",
		textAlign: "right",
	},
	whiteDetailText: {
		fontSize: 14,
		fontWeight: "600",
		fontFamily: "primaryFont",
		letterSpacing: 0.5,
		color: "white",
	},
});

export default Cart;
