import { create } from "zustand";
import { Product, Wishlist } from "../../models/DB";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";

export interface Categories {
	category: string;
	totalProducts: number;
	totalStock: number;
}

type State = {
	allProducts: Product[];
	allCategories: Categories[];
	wishlist: Wishlist[];
};

type Action = {
	updateProducts: (data: State[ "allProducts" ]) => void;
	updateProductWishlist: (data: Wishlist[]) => void;
};

export const productStore = create(
	persist<State & Action>((set, get) => ({
		allProducts: [],
		allCategories: [],
		wishlist: [],
		updateProducts: (data: Product[]) => {
		// remove duplicate products 
		const uniqueProducts = data.filter(
			(product, index, self) => index === self.findIndex((p) => p.id === product.id)
		);

		const groupedProducts = uniqueProducts.reduce((acc, product) => {
			const { category } = product;
			if (!acc[ category ]) {
				acc[ category ] = [];
			}
			acc[ category ].push(product);
			return acc;
		}, {} as Record<string, Product[]>);

		const categories = Object.entries(groupedProducts).map(([ category, categoryProducts ]) => {

			const totalStock = categoryProducts.reduce(
				(total, product) => total + product.stockOnHand,
				0
			);

			const totalProducts = categoryProducts.length;
			return {
				category,
				totalProducts,
				totalStock,
			};
		});

		// add an "All" category to the list of categories at the first index
		const allStock = uniqueProducts.reduce((total, product) => total + product.stockOnHand, 0);
		categories.unshift({ category: "All", totalProducts: uniqueProducts.length, totalStock : allStock });

		set(() => ({
			allProducts: uniqueProducts.sort((a, b) => a.name.localeCompare(b.name)),
			allCategories: categories,
		}));
	},
	updateProductWishlist: (wishlistItems: Wishlist[]) => {
		const currentProducts = get().allProducts;
		const wishlistCodes = new Set(wishlistItems.map(item => item.code));
		
		const updatedProducts = currentProducts.map(product => ({
			...product,
			isWishlistItem: wishlistCodes.has(product.code)
		}));

		set(() => ({
			allProducts: updatedProducts,
			wishlist: wishlistItems
			}));
		},
	}),
	{
		name: "product-store",
		storage: createJSONStorage(() => AsyncStorage),
	}
));
