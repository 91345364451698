import { create, StoreApi, UseBoundStore } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Product } from "../../models/DB";

interface CartItem {
	product: Product;
	quantity: number;
	selectedVariant?: string;
}

type CartState = {
	cartItems: CartItem[];
	cartTotal: number;
};

type CartActions = {
	addToCart: (product: Product, selectedVariant?: string) => void;
	removeFromCart: (productId: string, selectedVariant?: string) => void;
	updateCartItemQuantity: (productId: string, quantity: number, selectedVariant?: string) => void;
	clearCart: () => void;
};

export const cartStore = create(
	persist<CartState & CartActions>(
		(set) => ({
			cartItems: [],
			cartTotal: 0,
			addToCart: (product, selectedVariant) => {
				set((state) => {
					const existingCartItem = state.cartItems.find(
						(item) => item.product.code === product.code && item.selectedVariant === selectedVariant
					);

					let updatedCartItems;
					if (existingCartItem) {
						updatedCartItems = state.cartItems.map((item) =>
							item.product.code === product.code && item.selectedVariant === selectedVariant
								? { ...item, quantity: item.quantity + 1 }
								: item
						);
					} else {
						updatedCartItems = [...state.cartItems, { product, quantity: 1, selectedVariant }];
					}

					const cartTotal = updatedCartItems.reduce(
						(total, cartItem) => total + cartItem.product.price * cartItem.quantity,
						0
					);

					return { cartItems: updatedCartItems, cartTotal };
				});
			},
			removeFromCart: (productId, selectedVariant) => {
				set((state) => {
					const updatedCartItems = state.cartItems.filter(
						(item) => !(item.product.code === productId && item.selectedVariant === selectedVariant)
					);

					const cartTotal = updatedCartItems.reduce(
						(total, cartItem) => total + cartItem.product.price * cartItem.quantity,
						0
					);

					return { cartItems: updatedCartItems, cartTotal };
				});
			},
			updateCartItemQuantity: (productId, quantity, selectedVariant) => {
				set((state) => {
					const updatedCartItems = state.cartItems.map((item) =>
						item.product.code === productId && item.selectedVariant === selectedVariant
							? { ...item, quantity }
							: item
					);

					const cartTotal = updatedCartItems.reduce(
						(total, cartItem) => total + cartItem.product.price * cartItem.quantity,
						0
					);

					return { cartItems: updatedCartItems, cartTotal };
				});
			},
			clearCart: () => {
				set(() => ({
					cartItems: [],
					cartTotal: 0,
				}));
			},
		}),
		{
			name: "cart-storage", 
			storage: createJSONStorage(() => AsyncStorage),
		}
	)
) as unknown as UseBoundStore<StoreApi<CartState & CartActions>>;
