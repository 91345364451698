import { Container, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { ActivityIndicator } from "react-native";
import { primaryColor } from "../../../../config/constants";
import { WishlistTable } from "./components/WishlistTable";
import { Wishlist } from "../../../../models/DB";
import { WishlistService } from "../../../../services/supabase/WishlistService";

const ProductWishlist = () => {
    const wishlistService = WishlistService();
    const [isLoading, setIsLoading] = useState(false);

    const [wishlist, setWishlist] = useState<Wishlist[]>([]);

    useEffect(() => {
        const fetchWishlist = async () => {
            setIsLoading(true);
            const wishlist = await wishlistService.getAllWishlist();
            if (wishlist) {
                setWishlist(wishlist);
            }
            setIsLoading(false);
        };
        fetchWishlist();
    }, []);

    return (
        <Container component="main" maxWidth="lg" sx={{ paddingX: { xs: 0, md: 2 } }}>
            {
                isLoading ?
                    <Grid container justifyContent={"center"} mt={20}>
                        <Grid>
                            <ActivityIndicator
                                size="large"
                                color={primaryColor}
                            />
                        </Grid>
                    </Grid>
                    :
                    // <></>
                    <WishlistTable
                        wishlist={wishlist}
                    />
            }
        </Container>
    );
};

export default ProductWishlist;
