import { createStackNavigator } from "@react-navigation/stack";
import { NavBar } from "./NavBar";
import { navigationStore } from "../../../app/stores/Navigation";
import { useScreenComponent } from "../../../app/hooks/custom/useScreenComponent";

const Stack = createStackNavigator();

export const BarNavigation = () => {
	const { screenList, currentScreen } = navigationStore((state) => state);
	const { getScreenComponent } = useScreenComponent();

	return (
		<>
			<NavBar
				align="flex-start"
				screens={screenList}
			/>
			<Stack.Navigator
				initialRouteName={currentScreen.toString()}
				screenOptions={{
					headerShown: false,
				}}
			>
				{
					screenList.map((screen, index) => {
						return (
							<Stack.Screen
								key={index}
								name={screen.toString()}
								component={getScreenComponent(screen)}
								options={{ title: screen.replaceAll("_", " ").toString() }}
							// initialParams={{ screenName: screen }}
							/>
						);
					})
				}
			</Stack.Navigator>
		</>
	);
};
