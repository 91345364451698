import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Fragment, useState } from "react";
import uuid from "react-native-uuid";
import { cartStore } from "../../../app/stores/Cart";
import { ContactDetails, DeliveryDetails, ShippingDetails } from "../../../models/DB";
import { BasicButton } from "../../../components/BasicButton";
import { userStore } from "../../../app/stores/User";
import { navigationStore } from "../../../app/stores/Navigation";
import { DefaultRoute } from "../../../models/enums/DefaultRoute";
import { useScreenComponent } from "../../../app/hooks/custom/useScreenComponent";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { getDeliveryTypeSetup, getOrderPaymentDetails } from "../../../utils/customFunctions";
import Divider from "@mui/material/Divider";
import { VAT_PERCENTAGE } from "../../../config/constants";

interface ReviewProps {
	onBack: () => void;
	onConfirm: () => Promise<void>;
	shippingDetails: ShippingDetails & DeliveryDetails;
	contactDetails: ContactDetails;
}

export const Review = ({ onBack, shippingDetails, contactDetails, onConfirm }: ReviewProps) => {
	const { cartItems } = cartStore();
	const { user: { info: { email } } } = userStore((state) => state);
	const { updateShowNavigationBar, updateDefaultRoute } = navigationStore((state) => state);
	const [loading, setLoading] = useState(false);
	const { navigateToScreen } = useScreenComponent();

	const confirmOrder = async () => {
		try {
			if (!email) {
				console.log("Email is required");
				setLoading(false);
				updateShowNavigationBar(true);
				updateDefaultRoute(DefaultRoute.Login);
				navigateToScreen(ScreenComponent.Login);
				return;
			}

			await onConfirm();
		} catch (error) {
			console.error("Error confirming order:", error);
		}
	};

	// Address details
	const addresses = [
		shippingDetails?.addressLine1,
		shippingDetails?.addressLine2,
		shippingDetails?.city,
		shippingDetails?.region,
		shippingDetails?.zip,
		shippingDetails?.country,
	];

	const deliveryDetails = {
		deliveryType: shippingDetails?.deliveryType,
		suburb: shippingDetails?.suburb,
		deliveryFee: shippingDetails?.deliveryFee,
	};

	const handleConfirm = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		await confirmOrder();
		setLoading(false);
	};

	const DeliverTypeBlock = ({ deliveryType, deliveryFee, suburb }) => {
		const { backgroundColor, borderColor, textColor, text } = getDeliveryTypeSetup(deliveryType);
		return (
			<Grid container>
				<Grid
					item
					sx={{
						border: 2,
						borderColor: borderColor,
						backgroundColor: backgroundColor,
						borderRadius: 2,
						padding: 2,
						paddingY: 1
					}}
				>
					<Typography
						color={textColor}
						fontSize={13}
						fontWeight={"bold"}
						letterSpacing={0.5}
						fontFamily={"primaryFont"}
					>{`${text} - R ${deliveryFee}`}{suburb && ` - ${suburb}`}</Typography>
				</Grid>
			</Grid>
		)
	}

	const { baseCost, vat, totalCost, deliveryFee } = getOrderPaymentDetails(cartItems, deliveryDetails.deliveryFee);

	return (
		<Fragment>
			<Typography
				variant="h6"
				gutterBottom
			>
				Order summary
			</Typography>
			<List
				disablePadding
			>
				{cartItems.map((cartItem, index) => (
					<ListItem
						key={`${index}-${cartItem.product.code}`}
						sx={{ py: 1, px: 0 }}
					>
						<ListItemText
							primary={cartItem.product.name}
							secondary={`Code: ${cartItem.product.code.substring(0, 6).toUpperCase()}`}
						/>
						{
							cartItem.product.selectedVariant && (
								<ListItemText
									sx={{ textAlign: "right" }}
									primary={`Selected ${cartItem.product.variantType}`}
									secondary={
										`${cartItem.product.variantType}: ${cartItem.selectedVariant}`
									}
								/>
							)
						}
						<ListItemText
							sx={{ textAlign: "right" }}
							primary={`R${cartItem.product.price} x ${cartItem.quantity}`}
							secondary={`R ${(cartItem.product.price * cartItem.quantity).toFixed(2)}`}
						/>
					</ListItem>
				))}
				<Divider sx={{ my: 2 }} />
				<>
					<Grid container justifyContent="space-between" mt={1}>
						<Typography
							fontSize={12}
							letterSpacing={0.5}
							fontFamily={"primaryFont"}
						>
							Order Cost:
						</Typography>
						<Typography
							fontSize={12}
							letterSpacing={0.5}
							fontFamily={"primaryFont"}
						>
							R {baseCost.toFixed(2)}
						</Typography>
					</Grid>
					<Grid container justifyContent="space-between">
						<Typography
							fontSize={12}
							letterSpacing={0.5}
							fontFamily={"primaryFont"}
						>
							Delivery Fee:
						</Typography>
						<Typography
							fontSize={12}
							letterSpacing={0.5}
							fontFamily={"primaryFont"}
						>
							R {deliveryFee}.00
						</Typography>
					</Grid>
					<Grid container justifyContent="space-between">
						<Typography
							fontSize={12}
							letterSpacing={0.5}
							fontFamily={"primaryFont"}
						>
							VAT ({VAT_PERCENTAGE}%):
						</Typography>
						<Typography
							fontSize={12}
							letterSpacing={0.5}
							fontFamily={"primaryFont"}

						>
							R {vat.toFixed(2)}
						</Typography>
					</Grid>
				</>

				<ListItem sx={{ py: 1, px: 0 }}>
					<ListItemText primary="Total" />
					<Typography
						variant="subtitle1"
						sx={{ fontWeight: 700 }}
					>
						R {totalCost.toFixed(2)}
					</Typography>
				</ListItem>
			</List>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
					sm={6}
				>
					{/* Display delivery details */}
					<Grid
						item
						xs={12}
						sm={6}
					>
						<Typography
							variant="h6"
							gutterBottom
							sx={{ mt: 2 }}
						>
							Delivery Details
						</Typography>
						<DeliverTypeBlock {...deliveryDetails} />
					</Grid>
					{/* Display shipping details */}
					<Typography
						variant="h6"
						gutterBottom
						sx={{ mt: 2 }}
					>
						Shipping
					</Typography>
					{addresses.map((address, index) => (
						<Typography
							key={`${index}-${uuid.v4()}`}
							gutterBottom
						>
							{address}
						</Typography>
					))}
				</Grid>
				{/* Display contact details */}
				<Grid
					item
					xs={12}
					sm={6}
				>
					<Typography
						variant="h6"
						gutterBottom
						sx={{ mt: 2 }}
					>
						Contact Details
					</Typography>
					<Typography
						gutterBottom
					>{`${contactDetails?.firstName} ${contactDetails?.lastName}`}</Typography>
					<Typography gutterBottom>{`Email: ${contactDetails?.email}`}</Typography>
					<Typography gutterBottom>{`Mobile Number: ${contactDetails?.contactNo}`}</Typography>
				</Grid>
			</Grid>
			{/* Buttons to move to the previous and confirm order */}
			<Grid
				container
				justifyContent="space-between"
				mt={3}
			>
				<BasicButton
					title="Back"
					variant="outlined"
					onClick={onBack}
				/>
				{
					(
						<BasicButton
							title="Confirm Order"
							variant="contained"
							onClick={handleConfirm}
							isLoading={loading}
						/>
					)
				}
			</Grid>
		</Fragment>
	);
};
