import {
	DrawerContentScrollView,
	DrawerItem,
	createDrawerNavigator,
} from "@react-navigation/drawer";
import { View } from "react-native";
import { appConfigStore } from "../../../app/stores/AppConfig";
import { cartStore } from "../../../app/stores/Cart";
import { navigationStore } from "../../../app/stores/Navigation";
import { ScreenComponent } from "../../../models/enums/ScreenComponents";
import { Header } from "../../Header";
import { useScreenComponent } from "../../../app/hooks/custom/useScreenComponent";
import { BecomeMember } from "../../BecomeMember";
import { DefaultRoute } from "../../../models/enums/DefaultRoute";
import { CartIcon } from "../../CartIcon";
import { ProfileIcon } from "../../ProfileIcon";
import { userStore } from "../../../app/stores/User";
import { primaryColor } from "../../../config/constants";

const Drawer = createDrawerNavigator();

export const DrawerNavigation = () => {
	const {
		splashLoaded,
		currentScreen,
		screenList,
		drawerItemList,
	} = navigationStore((state) => state);

	const { getScreenComponent, navigateToScreen } = useScreenComponent();
	const { updateDefaultRoute } = navigationStore((state) => state);
	const cartItems = cartStore((state) => state.cartItems);
	const darkMode = appConfigStore((state) => state.darkMode);
	const { info, authenticated, role } = userStore((state) => state.user);

	const CustomDrawerContent = (props) => {
		return (
			<DrawerContentScrollView {...props}>
				<Header enableDivider={false} />
				{
					drawerItemList.map((screen, index) => {
						let isCurrentScreen = currentScreen === screen;

						if (authenticated && screen === "Login")
							return null;

						return (
							<DrawerItem
								key={"item_" + index}
								label={screen.replaceAll("_", " ")}
								labelStyle={{
									color: isCurrentScreen
										? darkMode
											? "white"
											: "white"
										: darkMode
											? "white"
											: "#212121",
								}}
								style={{
									backgroundColor: isCurrentScreen
										? darkMode
											? primaryColor
											: primaryColor
										: darkMode
											? "#212121"
											: "white",
								}}
								onPress={() => navigateToScreen(screen)}
							/>
						);
					})
				}
			</DrawerContentScrollView>
		);
	};

	return (
		<Drawer.Navigator
			useLegacyImplementation
			defaultStatus="closed"
			initialRouteName={currentScreen.toString()}
			drawerContent={(props) => <CustomDrawerContent {...props} />}
			screenOptions={{
				drawerType: "slide",
				headerShown: !splashLoaded ? false : true,
				headerTintColor: darkMode ? "white" : "black",
				drawerStyle: {
					width: "70%",
					backgroundColor: darkMode ? "#212121" : "white",
				},
				headerStyle: {
					minHeight: 85,
					backgroundColor: darkMode ? "#212121" : "white",
				},
				headerRight: () => {
					return (
						<View style={{ flexDirection: "row", marginRight: 7, gap: 14 }}>
							{/* {
								!authenticated &&
								<BecomeMember
									darkMode={darkMode}
									onClick={() => {
										updateDefaultRoute(DefaultRoute.Register);
										navigateToScreen(ScreenComponent.Register);
									}}
								/>
							} */}
							<ProfileIcon
								isAuthenticated={authenticated}
								firstName={info?.user_metadata?.firstName ?? ""}
								darkMode={darkMode}
								onClick={() => {
									updateDefaultRoute(DefaultRoute.Login);
									navigateToScreen(ScreenComponent.Login);
								}}
								role={role}
							/>
							{/* <CartIcon
								darkMode={darkMode}
								cartItems={cartItems}
								onClick={() => navigateToScreen(ScreenComponent.Cart)}
							/> */}
						</View>
					)
				},
			}}
		>
			{
				screenList.map((screen, index) => {
				return (
					<Drawer.Screen
						key={"screen_" + index}
						name={screen.toString()}
						component={getScreenComponent(screen)}
						options={{
							title: screen.replaceAll("_", " ").toString(),
							drawerLabel: screen.replaceAll("_", " ").toString(),
						}}
					/>
				)
			})}
		</Drawer.Navigator>
	);
};
