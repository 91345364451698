import {
	Grid,
} from "@mui/material";
import { StyleSheet, Text } from "react-native";
import { Product } from "../models/DB";
import { ProductItem } from "./ProductItem";

interface ProductItemProps {
	products: Product[];
	selectedCategory: string;
	navigation: any;
}

export const ProductsLayout = ({
	products,
	navigation
}: ProductItemProps) => {

	const Products = () => {
		return (
			<>
				{
					products.length > 0 ?
						products.map((product, index) => {
							return (
								<ProductItem
									key={`productItem_${index}_${product.id}`}
									product={product}
									navigation={navigation}
								/>
							);
						})
						:
						<Text style={styles.subtitle}>{`No items available`}</Text>
				}
			</>
		);
	};

	return (
		<Grid
			container
			gap={{ xs: 3, md: 4 }}
			justifyContent={{
				xs: "center",
				md: "flex-start"
			}}
			marginTop={{ xs: 4, md: 0 }}
			marginBottom={8}
		>
			<Products />
		</Grid>
	);
};

const styles = StyleSheet.create({
	background: {
		flex: 1,
	},
	image: {
		marginTop: 20,
		width: 50,
		height: 50,
		resizeMode: "contain",
		alignSelf: "center",
	},
	screenName: {
		margin: 16,
		alignSelf: "center",
		fontWeight: "500",
	},
	name: {
		fontSize: 16,
		fontWeight: "600",
		fontFamily: "primaryFont",
		letterSpacing: 0.5,
		color: "black",
	},
	price: {
		fontSize: 14,
		fontWeight: "600",
		fontFamily: "primaryFont",
		letterSpacing: 0.5,
		color: "gray",
	},
	title: {
		flex: 1,
		fontSize: 26,
		fontWeight: "600",
		fontFamily: "primaryFont",
		letterSpacing: 1,
		color: "black",
	},
	subtitle: {
		flex: 1,
		marginTop: 16,
		textAlign: "center",
		fontSize: 16,
		fontWeight: "600",
		fontFamily: "primaryFont",
		letterSpacing: 1,
		color: "gray",
	},
	chip: {
		marginRight: 8,
	},
});
