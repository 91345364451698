import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    FormControlLabel,
    Switch,
    Toolbar,
    Typography,
    IconButton,
} from "@mui/material";
import { useState } from "react";
import { Wishlist } from "../../../../../models/DB";
import { Refresh } from "@mui/icons-material";
import { ActivityIndicator } from "react-native";
import { primaryColor } from "../../../../../config/constants";
import { WishlistRow } from "./WishlistRow";
import { WishlistService } from "../../../../../services/supabase/WishlistService";

export const WishlistTable = ({ wishlist }: { wishlist: Wishlist[] }) => {
    const [allWishlistIterms, setAllWishlistItems] = useState<Wishlist[]>(wishlist)
    const [wishlistItems, setWishlistItems] = useState<'asc' | 'desc'>('desc');
    const [wishlistBy, setWishlistBy] = useState<keyof Wishlist>('code');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [dense, setDense] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { getAllWishlist } = WishlistService();

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - wishlist?.length) : 0;
    const sortedWishlist = allWishlistIterms?.slice().sort((a, b) => {

        // check for nulls

        if (a[wishlistBy] === null && b[wishlistBy] === null) {
            return 0;
        } else if (a[wishlistBy] === null) {
            return 1;
        } else if (b[wishlistBy] === null) {
            return -1;
        }

        if (wishlistBy === "code") {
            return (
                wishlistItems === 'desc' ?
                    a[wishlistBy]!.localeCompare(b[wishlistBy]!)
                    :
                    b[wishlistBy]!.localeCompare(a[wishlistBy]!)
            );
        } else if (typeof a[wishlistBy] === "number" && typeof b[wishlistBy] === "number") {
            return wishlistItems === 'asc' ?
                a[wishlistBy] - b[wishlistBy]
                :
                b[wishlistBy] - a[wishlistBy];
        } else {
            return 0;
        }
    });

    // let interval: NodeJS.Timeout;
    // useEffect(() => {
    //     interval = setInterval(async () => {
    //         await handleRefresh();
    //     }, 15000);

    //     return () => clearInterval(interval);
    // }, []);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Wishlist) => {
        const isAsc = wishlistBy === property && wishlistItems === 'asc';
        setWishlistItems(isAsc ? 'desc' : 'asc');
        setWishlistBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const handleRefresh = async () => {
        setIsLoading(true);
        const wishlistItems = await getAllWishlist();
        if (wishlistItems) setAllWishlistItems(wishlistItems);
        setIsLoading(false);
    };

    return (
        <Box
            sx={{
                width: '100%',
                mb: 4,
                mt: 4,
                boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.1)',
                borderRadius: 12,
            }}
        >
            <Paper sx={{ width: '100%', }}>
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 }
                    }}
                >
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Wishlist
                    </Typography>

                    <IconButton onClick={handleRefresh} disabled={isLoading} sx={{ mr: 2 }}>
                        {
                            isLoading ?
                                <ActivityIndicator
                                    size="small"
                                    color={primaryColor}
                                />
                                :
                                <Refresh />
                        }
                    </IconButton>
                    <FormControlLabel
                        label="Densify"
                        control={
                            <Switch
                                checked={dense}
                                onChange={handleChangeDense}
                                color={"secondary"}
                            />
                        }
                    />
                </Toolbar>
                <TableContainer>
                    <Table stickyHeader size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ ml: 8, fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={wishlistBy === "code"}
                                        direction={wishlistBy === "code" ? wishlistItems : "asc"}
                                        onClick={(event) => handleRequestSort(event, "code")}
                                    >
                                        Code
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={wishlistBy === "email"}
                                        direction={wishlistBy === "email" ? wishlistItems : "asc"}
                                        onClick={(event) => handleRequestSort(event, "email")}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={wishlistBy === "email"}
                                        direction={wishlistBy === "email" ? wishlistItems : "asc"}
                                        onClick={(event) => handleRequestSort(event, "email")}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={wishlistBy === "variant"}
                                        direction={wishlistBy === "variant" ? wishlistItems : "asc"}
                                        onClick={(event) => handleRequestSort(event, "variant")}
                                    >
                                        Product
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        sx={{ fontFamily: "primaryFont", fontWeight: "bold", letterSpacing: 1 }}
                                        active={wishlistBy === "created"}
                                        direction={wishlistBy === "created" ? wishlistItems : "asc"}
                                        onClick={(event) => handleRequestSort(event, "created")}
                                    >
                                        Liked at
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedWishlist?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((wishlistItem) => {
                                return (
                                    <WishlistRow
                                        key={wishlistItem.id}
                                        wishlist={wishlistItem}
                                    />
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 7, 10, 25, 50, 100]}
                    component="div"
                    count={wishlist?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};