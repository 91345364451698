import axios, { HttpStatusCode } from "axios";
import { ContactDetails, DeliveryDetails, OrderItem, ShippingDetails, OrderDetails, OrderCosting } from "../../models/DB";
import { ApiEndpoints } from "../../config/constants";
import uuid from "react-native-uuid";
import { getOrderPaymentDetails } from "../../utils/customFunctions";

const OrdersService = () => {

	const findOrderById = async (id: string): Promise<OrderDetails | null> => {
        try {
            const response = await axios.get(ApiEndpoints.Orders.GetById(id));
            const { data, statusCode, errorMessage } = response.data;

            if (statusCode === HttpStatusCode.Ok) {
                // Ensure the data is cast to OrderDetails
                return data as OrderDetails;
            }

            console.log("Error fetching order by id: ", errorMessage);
            return null;
        } catch (error) {
            console.error("Error fetching order by id:", error);
            return null;
        }
    };

    const createOrder = async (
		cartItems: OrderItem[], 
		contactDetails: ContactDetails, 
		shippingDetails: ShippingDetails & DeliveryDetails,
		email: string
	) => {
        try {
            // Generate a unique invoice ID
            const invoiceId = uuid.v4().toString();
            
            // Get the order costing details
            const { baseCost, vat, totalCost } = getOrderPaymentDetails(cartItems, shippingDetails.deliveryFee);
            const orderCosting: OrderCosting = {
                baseCost,
                vat,
                totalCost,
            };

            // Get the delivery details
            const deliveryDetails: DeliveryDetails = {
                deliveryType: shippingDetails.deliveryType,
                deliveryFee: shippingDetails.deliveryFee,
                suburb: shippingDetails.suburb,
            };

            // Remove delivery details from shipping details
            const { deliveryType, deliveryFee, suburb, ...shippingDetailsWithoutDelivery } = shippingDetails;

            // Create the order data of type OrderDetails
            const createOrderDto: OrderDetails = {
                reference: invoiceId,
                order: cartItems,
                shippingDetails: shippingDetailsWithoutDelivery,
                contactDetails,
                email,
                ...deliveryDetails,
                ...orderCosting,
            };

            // Create the order
            const response = await axios.post(ApiEndpoints.Orders.Create, createOrderDto);
            const { statusCode, errorMessage, data } = response.data;

            if (statusCode === HttpStatusCode.Ok) {
                return data;
            } else {
                console.log("Error creating order:", errorMessage);
                return null;
            }
        } catch (error) {
            console.error("Error creating order:", error);
            return null;
        }
    };

    return { createOrder, findOrderById };
};

export default OrdersService;
