import { Grid } from "@mui/material";
import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { ActivityIndicator, Animated, ScrollView, StyleSheet, View, Image } from "react-native";
import { Categories, productStore } from "../../../app/stores/Product";
import { ProductsLayout } from "../../../components/ProductsLayout";
import { isLarge } from "../../../navigation/HomeStack";
import { ProductsService } from "../../../services/supabase/ProductsService";
import { primaryColor } from "../../../config/constants";
import { CategoriesFilterScroller, CategoriesFilterSideMenu } from "./components/CategoryFilters";
import { userStore } from "../../../app/stores/User";
import { WishlistService } from "../../../services/supabase/WishlistService";

export const Shop = ({ navigation }) => {
	const { getWishlist } = WishlistService();
	const { getAllProducts } = ProductsService();

	const [categoriesAnimation] = useState(new Animated.Value(0));
	const [introImageAnimation] = useState(new Animated.Value(0));
	const [productsAnimation] = useState(new Animated.Value(0));

	const { allProducts, allCategories } = productStore((state) => state);
	const { user } = userStore((state) => state);
	const [selectedCategories, setSelectedCategories] = useState<Categories[]>([]);

	const paddingX = 4;
	const scrollViewRef = useRef<ScrollView>(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
	}, [selectedCategories])


	useEffect(() => {
		getAllProducts();
		console.log("requested products");
	}, []);

	useEffect(() => {
		if (user.authenticated) {
			setTimeout(() => {
				getWishlist(user?.info?.email ?? "");
				console.log("requested wishlist")
			}, 1000);
		}
	}, [user.authenticated]);

	useEffect(() => {
		if (allCategories.length > 0) {
			setSelectedCategories([allCategories[0].category as unknown as Categories]);
			fadeIn();
		}
	}, [allCategories]);

	const fadeIn = useCallback(() => {
		return Animated.timing(categoriesAnimation, {
			toValue: 1,
			duration: 600,
			delay: 250,
			useNativeDriver: false,
		}).start(() => {
			setIsLoading(false);
			Animated.timing(introImageAnimation, {
				toValue: 1,
				duration: 600,
				delay: 0,
				useNativeDriver: false,
			}).start(() => {
				Animated.timing(productsAnimation, {
					toValue: 1,
					duration: 600,
					delay: 0,
					useNativeDriver: false,
				}).start();
			});
		});
	}, [categoriesAnimation, introImageAnimation, productsAnimation]);

	const getProductsLength = useCallback((category) => {
		if (category === "All") return allProducts.length;
		return allProducts.filter((product) => product.category === category).length;
	}, [allProducts]);

	const CategoryProducts = useMemo(() => ({ category }) => {
		return (
			<React.Fragment key={category}>
				<Animated.View style={{ opacity: productsAnimation }}>
					<Grid
						container
						flexDirection={{ xs: "row", md: "column" }}
						sx={{
							paddingX: { xs: 2, md: paddingX },
							alignContent: { xs: "center", md: "flex-start" }
						}}
						marginTop={4}
					>
						<CategoriesFilterSideMenu
							scrollViewRef={scrollViewRef}
							allCategories={allCategories}
							selectedCategories={selectedCategories}
							setSelectedCategories={setSelectedCategories}
							getProductsLength={getProductsLength}
						/>
						<Grid
							item
							minWidth={"75%"}
							xs={12}
							md={9}
						>
							<ProductsLayout
								selectedCategory={category}
								products={category === "All" ?
									allProducts
									:
									allProducts.filter((product) => product.category === category)
								}
								navigation={navigation}
							/>
						</Grid>
					</Grid>
				</Animated.View>
			</React.Fragment >
		);
	}, [allCategories, selectedCategories, allProducts, productsAnimation]);

	const IntroImage = useMemo(() => () => {
		const backgroundImage = require("../../../assets/background-video.gif");
		return (
			<Animated.View style={{ opacity: introImageAnimation }}>
				<Grid
					container
					marginTop={4}
					marginBottom={2.5}
				>
					<Grid
						item
						flex={1}
						marginX={{ xs: 1.5, md: paddingX }}
						boxShadow={"0px 10px 10px rgba(0, 0, 0, 0.2)"}
						borderRadius={4}
						overflow={"hidden"}
						position="relative"
						justifyContent={"center"}
						alignItems={"center"}
						sx={{ aspectRatio: isLarge() ? 1 / 0.2 : 1 / 0.45 }}
					>
						<Image
							source={backgroundImage}
							style={{
								position: "absolute",
								width: "100%",
								height: "100%",
								resizeMode: "cover",
							}}
						/>
						<View style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							justifyContent: "center",
							alignItems: "center",
							zIndex: 2,
							opacity: 0.3,
							backgroundColor: "black"
						}} />
						<View style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							justifyContent: "center",
							alignItems: "center",
							zIndex: 2,
							opacity: 1
						}}>
							<Image
								source={require("../../../assets/overlay-logo.png")}
								style={{
									width: isLarge() ? "26%" : "52%",
									aspectRatio: 1,
									resizeMode: "contain",
								}}
							/>
						</View>
					</Grid>
				</Grid>
			</Animated.View>
		);
	}, [introImageAnimation]);

	return (
		<>
			{isLoading ? (
				<View style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center"
				}}>
					<ActivityIndicator
						size="large"
						color={primaryColor}
					/>
				</View>
			) : (
					<Animated.ScrollView
						ref={scrollViewRef}
						style={{ backgroundColor: "white" }}
						contentContainerStyle={styles.background}
						stickyHeaderIndices={[1]}
						stickyHeaderHiddenOnScroll={false}>
						<IntroImage />
						<CategoriesFilterScroller
							scrollViewRef={scrollViewRef}
							allCategories={allCategories}
							selectedCategories={selectedCategories}
							setSelectedCategories={setSelectedCategories}
							getProductsLength={getProductsLength}
						/>
						<View style={styles.container}>
							{selectedCategories.map((category, index) => (
								<CategoryProducts
									key={`${category}-${index}`}
									category={category}
								/>
							))}
						</View>
					</Animated.ScrollView>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	background: {
		backgroundColor: "transparent",
	},
	container: {
		flex: 1,
		backgroundColor: "transparent",
	},
	title: {
		flex: 1,
		fontSize: 26,
		fontWeight: "600",
		fontFamily: "primaryFont",
		letterSpacing: 1,
		color: "black",
	},
	image: {
		marginTop: 20,
		width: 50,
		height: 50,
		resizeMode: "contain",
		alignSelf: "center",
	},
	headerTitle: {
		flex: 1,
		fontSize: 48,
		fontWeight: "800",
		fontFamily: "primaryFont",
		letterSpacing: 1.5,
		color: "white",
	},
	categoryTitle: {
		fontSize: 48,
		fontWeight: "800",
		fontFamily: "primaryFont",
		letterSpacing: 1.5,
		color: "white",
	},
	chipContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 10,
	},
	chip: {
		letterSpacing: 0.5,
		fontFamily: "primaryFont",
		fontWeight: "600",
		minHeight: 40,
		marginRight: 8,
		borderRadius: 20,
		borderWidth: 2,
	},
});