import React, { useEffect, useState } from "react";
import { Text, StyleSheet, Animated } from "react-native";
import { BasicButton } from "../../components/BasicButton";
import { BasicTextField } from "../../components/BasicTextField";
import { Container, Box, Grid, IconButton } from "@mui/material";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import AuthService from "../../services/supabase/AuthService";
import { RouteProp, useIsFocused, useRoute } from "@react-navigation/native";
import * as _ from "lodash";
import { primaryColor, stateErrorColor } from "../../config/constants";
import Icon from "react-native-vector-icons/Ionicons";
import { WishlistService } from "../../services/supabase/WishlistService";
import { productStore } from "../../app/stores/Product";

// Define the type for route parameters
type LoginRouteParams = {
	firstName?: string;
	error?: string;
	confirmationURL?: string;
};

type LoginScreenRouteProp = RouteProp<{ Login: LoginRouteParams }, 'Login'>;

export const Login = () => {
	const route = useRoute<LoginScreenRouteProp>();
	const isFocused = useIsFocused();
	const { navigateToScreen } = useScreenComponent();
	const { getWishlist } = WishlistService();
	const { updateProductWishlist } = productStore((state) => state);
	const { signInWithEmailAndPassword } = AuthService();

	const [fadeAnimation] = useState(new Animated.Value(0));
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [firstName, setFirstName] = useState<string | null>(null);
	const [passwordVisible, setPasswordVisible] = useState(false);

	useEffect(() => {
		if (isFocused) {
			fadeIn();

			const hasParams = _.has(route, 'params');
			const hasFirstName = _.has(route.params, 'firstName');
			const hasErrorMessage = _.has(route.params, 'error');

			if (hasParams && hasFirstName) {
				setFirstName(route.params.firstName ?? "Sign In");
			}

			if (hasParams && hasErrorMessage) {
				setErrorMessage(route.params.error ?? "An error occurred");
			}
		}
	}, [isFocused]);

	const fadeIn = () => {
		return Animated.timing(fadeAnimation, {
			toValue: 1,
			duration: 750,
			useNativeDriver: false,
		}).start();
	};

	const fadeOut = (doAfter: () => void) => {
		return Animated.timing(fadeAnimation, {
			toValue: 0,
			duration: 750,
			useNativeDriver: false,
		}).start(() => {
			doAfter();
		});
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		setErrorMessage(null);
		const data = new FormData(event.currentTarget);
		const email = data.get("email") as string;
		const password = data.get("password") as string;

		const { success, message } = await signInWithEmailAndPassword(email, password);

		if (success) {
			getWishlist(email);
			navigateToScreen(ScreenComponent.Shop);
		}
		else setErrorMessage(message);
	};

	const handlePasswordVisibilityToggle = () => {
		setPasswordVisible(!passwordVisible);
	};

	return (
		<Animated.View
			style={{
				opacity: fadeAnimation,
			}}
		>
			<Container
				component={"main"}
				maxWidth={"sm"}
				sx={{ mt: 4 }}
			>
				<Box
					sx={{
						marginTop: { sm: 4 },
						display: "flex",
						flexDirection: "column",
						backgroundColor: "white",
						borderRadius: 2,
					}}
					boxShadow={"0px 8px 10px rgba(0, 0, 0, 0.1)"}
				>
					<Box
						component={"form"}
						onSubmit={handleSubmit}
						sx={{ m: 8 }}
					>
						<Grid
							container
							mb={2}
							flexDirection={"column"}
							spacing={1}
						>
							{
								firstName ?
									(
										<>
											<Grid item>
												<Text style={styles.title}>Welcome, {firstName}</Text>
											</Grid>
											<Grid item>
												<Text style={styles.subtitle}>Sign in to continue</Text>
											</Grid>
										</>
									)
									:
									(
										<>
											<Grid item>
												<Text style={styles.title}>Sign In</Text>
											</Grid>
											<Grid item>
												<Text style={styles.subtitle}>Your personalised experience</Text>
											</Grid>
										</>
									)
							}
						</Grid>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								xs={12}
							>
								<BasicTextField
									required
									fullWidth
									id={"email"}
									name={"email"}
									size={"small"}
									label={"Email"}
									margin={"normal"}
									inputProps={{ style: { color: "black" } }}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<BasicTextField
									required
									fullWidth
									id={"password"}
									name={"password"}
									size={"small"}
									label={"Password"}
									type={passwordVisible ? "text" : "password"}
									inputProps={{ style: { color: "black" } }}
									InputProps={{
										endAdornment: (
											<IconButton onClick={handlePasswordVisibilityToggle}>
												<Icon size={20} name={passwordVisible ? "eye-off" : "eye"} />
											</IconButton>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} justifyContent={"center"} alignItems={"center"}>
								<Text style={styles.errorMessage}>{errorMessage}</Text>
							</Grid>
						</Grid>
						<Grid
							container
							mt={2}
							direction={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							spacing={2}
						>
							<Grid item>
								<BasicButton
									title={"Login"}
									type={"submit"}
									style={{ marginTop: 16, marginBottom: 6, width: 130, backgroundColor: primaryColor }}
									isLoading={loading}
								/>
							</Grid>
							<Grid item>
								<BasicButton
									title={"Register"}
									variant={"text"}
									style={{ color: primaryColor }}
									onClick={() => {
										fadeOut(() => {
											// updateDefaultRoute(DefaultRoute.Register);
											navigateToScreen(ScreenComponent.Register);
										});
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	title: {
		fontSize: 22,
		fontWeight: "600",
		fontFamily: "primaryFont",
		marginBottom: 4,
		textAlign: "center",
		color: "black",
	},
	subtitle: {
		fontSize: 16,
		fontWeight: "600",
		fontFamily: "primaryFont",
		textAlign: "center",
		color: "black",
	},
	errorMessage: {
		color: stateErrorColor,
		textAlign: "center",
		marginTop: 10,
		fontFamily: "primaryFont",
	},
});
