import { userStore } from "../../app/stores/User";
import { Portal } from "./Portal";
import { PortalAuthStack } from "./Auth/PortalAuthStack";

export const PortalRoutes = () => {
    const { authenticated } = userStore(state => state.user);

    return (
        authenticated ?
            <Portal />
            :
            <PortalAuthStack />
    );
};