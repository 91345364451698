import axios, { HttpStatusCode } from "axios";
import { Product, Wishlist } from "../../models/DB";
import { ResponseModel } from "../../models/Response";
import { ApiEndpoints } from "../../config/constants";
import { productStore } from "../../app/stores/Product";

export const WishlistService = () => {
	const updateProductWishlist = productStore((state) => state.updateProductWishlist);

	const addToWishlist = async (code: string, userId: string, email: string, firstName: string, lastName: string, product: Product, variant?: string) => {
		try {
			const response = await axios.post(ApiEndpoints.Wishlist.AddToWishlist, { 
					code,
					userId,
					email,
					variant,
					product,
					firstName,
					lastName
				}
			);
			const { data, statusCode, errorMessage } = response.data;

			if (statusCode === HttpStatusCode.Ok && data) {
				return data;
			}
			console.error("Error adding to wishlist:", errorMessage);
			return false;
		} catch (error) {
			console.log("Error adding to wishlist:", error);
		}
	};

	const getWishlist = async (email: string) => {
		try {
			const response = await axios.get<ResponseModel<Wishlist[]>>(ApiEndpoints.Wishlist.GetWishlist(email));
			const { data, statusCode, errorMessage } = response.data;

			if (statusCode === HttpStatusCode.Ok && data) {
				setTimeout(() => {
					updateProductWishlist(data);
				}, 0);
				return;
			}
			console.error("Error getting wishlist:", errorMessage);
			return false;
		} catch (error) {
			console.log("Error getting wishlist:", error);
			return false;
		}
	};

	const getAllWishlist = async () => {
		try {
			const response = await axios.get<ResponseModel<Wishlist[]>>(ApiEndpoints.Wishlist.GetAllWishlist);
			const { data, statusCode, errorMessage } = response.data;

			if (statusCode === HttpStatusCode.Ok && data) {
				return data;
			}
			console.error("Error getting wishlist:", errorMessage);
			return false;
		} catch (error) {
			console.log("Error getting wishlist:", error);
			return false;
		}
	};

	const removeFromWishlist = async (id: string) => {
		try {
			const response = await axios.delete(ApiEndpoints.Wishlist.RemoveFromWishlist(id));
			const { data, statusCode, errorMessage } = response.data;

			if (statusCode === HttpStatusCode.Ok && data) {
				return data;
			}
			console.error("Error removing from wishlist:", errorMessage);
			return false;	
		} catch (error) {
			console.log("Error removing from wishlist:", error);
		}
	};

	return { addToWishlist, getWishlist, getAllWishlist, removeFromWishlist };
};
