import AsyncStorage from "@react-native-async-storage/async-storage";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type State = {
	darkMode: boolean;
};

type Action = {
	updateDarkMode: (data: State["darkMode"]) => void;
};

export const appConfigStore = create(
	persist<State & Action>(
		(set) => ({
			darkMode: false,
			updateDarkMode: (data) => set(() => ({ darkMode: data })),
		}),
		{
			name: "app-config-storage",
			storage: createJSONStorage(() => AsyncStorage),
		}
	)
);
