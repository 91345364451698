import Divider from "@mui/material/Divider";
import { Image, StyleSheet, Text, View } from "react-native";
import { BasicButton } from "../../components/BasicButton";
import { ScreenComponent } from "../../models/enums/ScreenComponents";
import { useScreenComponent } from "../../app/hooks/custom/useScreenComponent";
import { primaryColor, AppLogo } from "../../config/constants";

export const Splash = () => {
	const { navigateToScreen } = useScreenComponent();

	const HeroView = () => {
		return (
			<View style={styles.heroContainer}>
				<Image
					style={styles.image}
					source={require(`../../assets/${AppLogo}`)}
				/>
				<Text style={styles.heroTitleText}>SOHK</Text>
			</View>
		);
	};

	const GetInTouch = () => {

		return (
			<View style={styles.getInTouchContainer}>
				<Divider
					flexItem
					color="white"
					orientation="horizontal"
					style={styles.divider}
				/>
				<View style={styles.tourContainer}>
					<BasicButton
						title={"Go To Store"}
						type="submit"
						onClick={() => navigateToScreen(ScreenComponent.Shop)}
						style={{ width: 130, backgroundColor: primaryColor }}
					/>
				</View>
			</View>
		);
	};

	return (
		<View style={styles.background}>
			<HeroView />
			<GetInTouch />
		</View>
	);
};

const styles = StyleSheet.create({
	background: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
	},
	divider: {
		marginTop: 24,
		width: 250,
		alignSelf: "center",
	},
	image: {
		width: 100,
		aspectRatio: 1,
		borderRadius: "50%",
		resizeMode: "contain",
		alignSelf: "center",
	},
	heroContainer: {
		flex: 3,
		width: "70%",
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
	},
	getInTouchContainer: {
		flex: 2,
		marginTop: 12,
		width: "70%",
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
	},
	heroTitleText: {
		marginTop: 22,
		color: "white",
		fontSize: 22,
		fontWeight: "800",
	},
	heroSubText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
		textAlign: "center",
		marginTop: 8,
	},
	getInTouchSubText: {
		marginTop: 12,
		marginBottom: 18,
		color: "white",
		fontSize: 14,
		fontWeight: "600",
		textAlign: "center",
	},
	skipContainer: {
		marginTop: 32,
		flexDirection: "row",
		alignItems: "center",
	},
	skipOrText: {
		color: "white",
		fontSize: 14,
		fontWeight: "600",
		marginHorizontal: 4,
	},
	tourContainer: {
		width: "70%",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		marginTop: 28,
	},
});
