export interface ShippingFormSuburb {
    name: string;
    selectable: boolean;
}

export interface ShippingFormCity {
    name: string;
    nextDayDelivery: boolean;
    standardDelivery: boolean; // Add this line
    suburbs: ShippingFormSuburb[];
}

export interface ShippingFormState {
    name: string;
    selectable: boolean;
}

export const cities: ShippingFormCity[] = [
    {
        name: "Durban",
        nextDayDelivery: true,
        standardDelivery: true,
        suburbs: [
            { name: "Morningside", selectable: true },
            { name: "Overport", selectable: true },
            { name: "Durban North", selectable: true },
            { name: "Parlock", selectable: true },
            { name: "Newlands East", selectable: true },
            { name: "Newlands West", selectable: true },
        ],
    },
    {
        name: "Johannesburg",
        standardDelivery: true,
        nextDayDelivery: false,
        suburbs: [],
    },
    {
        name: "Cape Town",
        standardDelivery: true,
        nextDayDelivery: false,
        suburbs: [],
    },
];

export const states: ShippingFormState[] = [
    { name: "Eastern Cape", selectable: false },
    { name: "Free State", selectable: false },
    { name: "Gauteng", selectable: true },
    { name: "KwaZulu-Natal", selectable: true },
    { name: "Limpopo", selectable: false },
    { name: "Mpumalanga", selectable: false },
    { name: "Northern Cape", selectable: false },
    { name: "North West", selectable: false },
    { name: "Western Cape", selectable: true },
];