import { Session, User } from "@supabase/supabase-js";
import { create } from "zustand";
import { AccessLevel } from "../../models/enums/AccessLevel";

export interface UserInfo {
	info: User;
	session: Session;
	role: AccessLevel;
	authenticated: boolean;
}

type State = {
	user: UserInfo;
};

type Action = {
	updateUser: (data: State["user"]) => void;
};

export const userStore = create<State & Action>((set) => ({
	user: {
		info: new Object() as User, 
		session: new Object() as Session,
		authenticated: false,
		role: AccessLevel.User
	},
	updateUser: (data) =>
		set(() => ({
			user: {
				info: data.info,
				session: data.session,
				authenticated: data.authenticated,
				role: data.role
			},
		})),
}));
